export default {
    "language": "Svenska",
    // 导航
    "zdy": {
        "tjsq": "lämna in ansökan",
        "czsl": "Ladda kvantitet",
        "enterczsl": "Ange laddningsbeloppet",
        "qbdz": "plånboksadress",
        "enterqbdz": "Vänligen ange plånboksadress",
        "sftjmcj": "Om beställningens försäljningspris ska lämnas",
        "sftjmrj": "Om köpeskillingen för beställningen ska lämnas",
        "proloss": "Ta vinst och stoppa förlusten",
    },
    "header": {
        "home": "framsida",
        "markets": "Citat",
        "fiat": "Fiat valutahandel",
        "c2c": "C2C transaktion",
        "exchange": "Handel med kryptovalutor",
        "lever": "kontraktshandel",
        "micro": "andra kontraktshandel",
        "myshop": "min butik",
        "assets": "mina tillgångar",
        "help": "Hjälpcenter",
        "login": "Logga in",
        "sign": "Registrera",
        "accountSet": "kontoinställningar",
        "tradeLog": "Transaktionsloggen",
        "receiveSet": "Betalningsinställningar",
        "identify": "Autentisering",
        "jchange": "Valutaväxling",
        "logout": "logga ut",
        "themedark": "Nattläge",
        "themeday": "dagsläge",
    },
    "frame": {
        "a": "Du har en ny status för uttagsorder, klicka för att se den",
        "b": "Annullera",
        "c": "Säker",
        "con": "Kära användare, för det finns många medlemmar som handlar i handelscentret. ",
    },
    // 登录注册
    "lg": {
        "login": "Logga in",
        "register": "Registrera",
    },
    // 首页
    "home": {
        "vol": "24H kvantitet",
        "c01": "Världens ledande plattform för handel med digitala tillgångar",
        "c02": "Tillhandahålla säkra och pålitliga tjänster för handel med digitala tillgångar och tillgångsförvaltning till miljontals användare i mer än 130 länder runt om i världen",
        "c03": "Trygg och pålitlig",
        "c04": "5 års erfarenhet av digitala tillgångar finansiella tjänster",
        "c05": "Professionell distribuerad arkitektur och anti-DDOS attacksystem",
        "c06": "Global ekologisk layout",
        "c07": "Lokala handelsservicecenter har etablerats i många länder",
        "c08": "Skapa ett blockchain-ekosystem som integrerar flera affärsformer",
        "c09": "Användare först",
        "c10": "Upprätta en avancerad kompensationsmekanism",
        "c11": "Etablera en investerarskyddsfond",
        "c12": "Flerplattformsterminalhandel när som helst, var som helst",
        "c13": "Täcker flera plattformar inklusive iOS, Android och Windows, med stöd för alla affärsfunktioner",
        "sweep": "Skanna koden för att ladda ner",
        "android": "Android",
        "pinput": "Vänligen ange din e-postadress eller ditt mobilnummer",
        "atrade": "Registrera ett FutureInvestment Global Station-konto och påbörja din handelsresa",
        "download": "Ladda ner APP",
        "ios": "Ladda ner IOS",
        "androidDownload": "Android nedladdning",
        companyoverview1: "Säker",
        companyoverview2: "5 års erfarenhet av digitala tillgångar finansiella tjänster Professionell distribuerad arkitektur och anti DDoS Attack System.",
        companyoverview3: "Pålitlig",
        companyoverview4: "Snabb"
    },
    // 底部
    "foo": {
        "about": "om oss",
        "support": "Användarstöd",
        "contact": "kontakta oss",
        "qq": "Kundtjänst QQ",
        "email": "Kontakt e-post",
    },
    // 行情
    "market": {
        "handicap": "Handikapp",
        "exchange": "mynt",
        "selfmarket": "Frivillig",
        "allmarket": "Alla citat",
        "symbol": "handelspar",
        "newprice": "Senaste priset",
        "change": "Öka",
        "highprice": "högsta priset",
        "lowprice": "lägsta pris",
        "vol": "24H kvantitet",
    },
    // 公共交易
    "td": {
        "buy": "köpa",
        "sell": "sälja",
        "currency": "Valuta",
        "num": "kvantitet",
        "time": "tid",
        "limit": "begränsa",
        "price": "enhetspris",
        "method": "betalningsmetod",
        "total": "klumpsumma",
        "inwant": "Vänligen ange önskat",
        "do": "fungera",
        "more": "ladda mer",
        "nomore": "inte mer",
        "nodata": "Inga data",
        "trade": "handel",
        "buynum": "Köpmängd",
        "sellout": "sälja",
        "buyin": "köpa",
        "all": "Allt",
        "allbuy": "köp allt",
        "allsell": "sälj allt",
        "buytotal": "Ange det totala beloppet du vill köpa",
        "selltotal": "Ange det totala beloppet du vill sälja",
        "buyallnum": "Ange den kvantitet du vill köpa",
        "sellallnum": "Ange den kvantitet du vill sälja",
        "tradeTotal": "totala transaktionsbeloppet",
        "doceil": "Avbryt automatiskt efter sekunder",
        "place": "Beställa",
        "waitpay": "Väntar på betalning",
        "paycode": "betalningskod",
        "finished": "avslutad",
        "ceiled": "Inställt",
        "payed": "Redan betalat",
        "nofinish": "uppknäppt",
        "buyer": "köpare",
        "seller": "säljare",
        "callWay": "Kontaktinformation",
        "placeTime": "beställningstid",
        "renum": "referensnummer",
        "canceil": "Annullera",
        "confirm": "bekräfta",
        "pwd": "Vänligen ange transaktionslösenord",
        "coincode": "Valutaenhet",
        "pcoin": "Välj valutaenhet",
        "setTradePwd": "Ange transaktionslösenord?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Orderrekord",
        "fatdLog": "Lagliga valutatransaktionsposter",
        "shoper": "Handlare",
        "faLog": "Lagliga valutatransaktionsposter",
        "tdType": "Överföringstyp",
        "odStatus": "Orderstatus",
        "pwaitPay": "Vänta på köparens betalning",
        "odFinish": "Order slutförd",
        "odCeil": "Beställning annullerad",
        "odPay": "Betalning har gjorts, kontrollera betalningsinformationen noggrant",
        "tdCeil": "avbryta affären",
        "pCeil": "Om du redan har betalat säljaren, vänligen avbryt inte transaktionen.",
        "paySure": "Betalningsbekräftelse",
        "pdopay": "Vänligen bekräfta att du har betalat säljaren",
        "badClick": "Skadliga klick kommer att frysa kontot direkt",
        "receivePay": "Betalningsbekräftelse",
        "payReceive": "Vänligen bekräfta att köparen har betalat dig",
        "receivePays": "Bekräfta mottagen betalning",
        // LegalPay
        "payPlease": "var snäll och betala",
        "tdPrice": "Transaktionsenhetspris",
        "tdNum": "Antal transaktioner",
        "payinfo": "Betalningsinfo",
        "banknum": "Bankkonto",
        "realName": "verkliga namnet",
        "shoperZname": "Handelsfilial",
        "shoperNum": "handlarkonto",
        "odCeil": "avbryta beställningen",
        "imPay": "Jag har betalat, klicka för att bekräfta",
        // LegalSeller
        "register_time": "Registreringstid",
        "odtotal": "Monteringsordning",
        "odmonth": "Beställningen slutfördes den 30:e",
        "odfinish": "Komplett beställning",
        "odrate": "Färdigställandegrad",
        "submit": "släpp",
        "phone": "mobiltelefon",
        "mysell": "min till salu",
        "mybuy": "Mitt köp",
        "realAuth": "Verifierad",
        "advAuth": "Avancerad certifiering",
        "linebuy": "köp på nätet",
        "linesell": "till salu online",
        "tipPay": "Vänligen kontakta handlaren inom 24 timmar för att betala.",
        "notlow": "Kan inte gå under minimum",
        "nothigh": "Kan inte överskrida maxgränsen",
        "notnum": "Kan inte överskrida maxmängden",
        // LegalShopDetail
        "type": "typ",
        "status": "stat",
        "seeOrder": "kontrollera ordning",
        "withdraw": "dra tillbaka",
        "abnormal": "onormal",
        "lowershelf": "Borttagen från hyllorna",
        "protection": "Rättighetsskydd pågår",
        "apply": "Ansök om rättighetsskydd",
        "psType": "Välj typ",
        "pselect": "Vänligen välj",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "bankkort",
        "minNum": "Minsta transaktionsvolym",
        "maxNum": "maximal handelsvolym",
        "wantBuy": "Vill köpa",
        "pnums": "Vänligen ange antal",
        "pmin": "Vänligen ange lägsta transaktionsvolym",
        "pmax": "Ange maximal transaktionsvolym",
        "pnot": "Den maximala transaktionsvolymen får inte vara mindre än den lägsta transaktionsvolymen",
        // MyLegalShops
        "myshops": "min butik",
        "name": "namn",
        "fiatSub": "Laglig valuta",
        "shoper_balance": "Saldo för säljare",
        "enterShop": "gå in i en butik",
        // shop_legal_pay_detail
        "payedWait": "Betalning har gjorts, väntar på verifiering och bekräftelse",
        "yes": "ja",
        "no": "Nej",
        "sd": "Lås balansen",
        "journal": "Balanslogg",
        "business": "Handlare",
        "user": "användare",
        "backdone": "Indragen",
        "busbalance": "Saldo för säljare",
        "userbalance": "Användarsaldo",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C publicerad av mig",
        "myTrade": "C2C för min transaktion",
        "and": "höger",
        "balance": "Balans",
        "buynum": "Köpvolym",
        "sellnum": "Försäljningsvolym",
        "bankTransfer": "Banköverföring",
        "payim": "Måste betala personligen",
        "pbuyNum": "Vänligen ange inköpskvantiteten",
        "pprice": "Ange enhetspris",
        "payway": "Vänligen välj betalningssätt",
        "psellNum": "Vänligen ange säljkvantitet",
        "suerePlace": "Bekräfta order?",
        "detail": "Detaljer",
        "payinfo": "Betalningsinfo",
        "Payee": "Betalningsmottagare",
        "total": "klumpsumma",
        "name": "namn",
        "cardnum": "kortnummer",
        "receivePay": "Bekräfta mottagen betalning",
        "ceilConfirm": "Bekräfta att avbryta transaktionen?",
        "paySeller": "Bekräfta att du har betalat säljaren Med skadliga klick kommer ditt konto att frysas.",
        "payBuyer": "Vänligen bekräfta att du har fått betalningsinformation från köparen",
        "account": "konto",
        "sellerPay": "Vänligen bekräfta att köparen har betalat dig?",
        "payattion": "Vänligen betala säljaren så snart som möjligt efter att betalningen har bekräftats. Skadliga klick fryser kontot direkt.",
        "log": "c2c slutförd beställning",
    },
    // 合约
    "lever": {
        "nowentrust": "Aktuell beställning",
        "hisentrust": "historiska uppdrag",
        "lvchi": "Kontraktsbefattningar",
        "all": "Allt",
        "or": "eller",
        "gotrade": "Börja handla",
        "tdrecord": "Transaktionspost",
        "dealed": "Affären klar",
        "notdeal": "Ingen transaktion",
        "loading": "läser in...",
        "entotal": "Totalt anfört belopp",
        "canuse": "Tillgängliga",
        "std": "marknadspristransaktion",
        "xtd": "begränsa handeln",
        "pprice": "Vänligen ange pris",
        "phand": "Vänligen ange inköpspartiets storlek",
        "phandsell": "Vänligen ange storleken på försäljningspartiet",
        "pwd": "Lösenord",
        "ppwd": "Vänligen ange transaktionslösenord",
        "psw": "transaktionslösenord",
        "equal": "likvärdig",
        "times": "gånger",
        "timed": "flera olika",
        "hand": "hand",
        "hands": "antal steg",
        "ptimes": "Välj flera",
        "phands": "Välj partistorlek",
        "contractVal": "Kontraktsmarknadsvärde",
        "bail": "marginal",
        "canBail": "Tillgänglig marginal",
        "charge": "Transaktionsserviceavgift",
        "domore": "Köp (gå långt)",
        "doshort": "Sälj (kort)",
        "dmore": "Gå långt",
        "dshort": "kort",
        "sureOd": "Bekräfta order",
        "noless": "Partistorleken får inte vara mindre än",
        "nomore": "Partistorleken kan inte vara större än",
        "tdnum": "Handelsvolym",
        "risk": "risknivå",
        "allloss": "Total vinst och förlust av positioner",
        "onehouse": "Stäng en position med ett klick",
        "type": "typ",
        "entrustPrice": "Beställningspris",
        "openPrice": "öppningspris",
        "nowPrice": "nuvarande pris",
        "styPrice": "Ta vinstpriset",
        "stsPrice": "Stop loss pris",
        "openTime": "Öppnings tid",
        "closeTime": "Stängningstid",
        "rate": "expeditionsavgift",
        "nightFee": "Övernattningsavgift",
        "loss": "Vinst och förlust",
        "setloss": "Ställ ta vinst och stoppa förlust",
        "expectProfit": "förväntad vinst",
        "expectLoss": "förväntad förlust",
        "allClose": "Stäng alla positioner",
        "moreClose": "Stäng bara långa beställningar",
        "nullClose": "Stäng bara korta beställningar",
        "sureClose": "Bekräfta att stänga positionen?",
        "thanZearo": "Värdet måste vara större än 0",
        "listin": "väntande beställning",
        "tdin": "i transaktion",
        "closein": "Stängning av position",
        "closed": "Position stängd",
        "revoked": "Återkallad",
        "revokeOrder": "Är du säker på att du vill avbryta beställningen?",
        "ping": "Stäng läge",
        "revoke": "Avbryt beställning",
        "sureping": "Är du säker på att stänga positionen?",
        "thanzone": "Värdet måste vara större än 0",
    },
    // 币币
    "cuy": {
        "direction": "riktning",
        "total": "total",
        "price": "pris",
        "sell": "Sälja",
        "buy": "inköp",
        "allStation": "Handel med hela webbplatsen",
        "buyPrice": "köppris",
        "buynum": "Köpvolym",
        "sellPrice": "Säljande pris",
        "sellnum": "Försäljningsvolym",
        "tdPrice": "Transaktionsvolym",
        "or": "eller",
        "tdStart": "Börja handla",
        "pbPrice": "Vänligen ange inköpspriset",
        "pbNum": "Vänligen ange köpsumman",
        "psPrice": "Vänligen ange försäljningspriset",
        "psNum": "Vänligen ange försäljningsbeloppet",
        "fixPrice": "begränsa handeln",
        "Mtrans": "marknadspristransaktion",
        "available": "Tillgängliga",
        "revoke": "Annullera",
        "loading": "läser in",
        "confirmCancel": "Är du säker på att du vill avbryta?",
        "confirm": "Säker",
        "cancel": "Annullera",
        "nomore": "Inga mer data",
        "evelPrice": "genomsnittligt transaktionspris",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Vill du bekräfta överföringen?",
        "assets": "tillgångar",
        "currency_account": "Myntkonto",
        "lever_account": "Avtalskonto",
        "fince_account": "Fiat konto",
        "change_account": "transaktionskonto",
        "micro_account": "andra kontraktskontot",
        "miscroAccount": "andra kontraktskontot",
        "assets_hua": "Fondöverföring",
        "all_assets": "Totala tillgångar konverterade",
        "currency": "Valuta",
        "canuse": "Tillgängliga",
        "frezz": "frysa",
        "conversion": "konverterad",
        "charging": "Sätt in mynt",
        "withdraw": "Ta ut mynt",
        "address_bind": "Bind uttagsadressen",
        "address_withdraw": "Adresshantering för myntuttag",
        "record": "Detaljer",
        "tbrecord": "Rekord för myntuttag",
        "address_charge": "Insättningsadress",
        "address_width": "Uttagsadress",
        "copy": "kopiera",
        "ercode": "QR-kod",
        "reminder": "Snälla tips",
        "notcharge": "Vänligen sätt inte in några pengar till ovanstående adress.",
        "ornone": ", annars kommer tillgångarna inte att återvinnas",
        "support": "Insättningar stöder endast den enkla sändningsmetoden Insättningar med andra metoder (skicka alla) kan inte krediteras tillfälligt.",
        "minnum": "Minsta uttagsbelopp",
        "ratenum": "expeditionsavgift",
        "havenum": "Ankomstmängd",
        "from": "från",
        "to": "till",
        "transfer": "överföra",
        "tfnum": "Överföringsmängd",
        "ptfnum": "Vänligen ange minimum",
        "dtfnum": "Antalet överföringar",
        "canbalance": "Tillgängligt Saldo",
        "sureConfirm": "Bekräfta överföringen",
        "moneyRecord": "finansiella register",
        "pNum": "Ange överföringsbeloppet",
        "leverUse": "Kontraktskonton finns tillgängliga",
        "legalUse": "Fiat-konton är tillgängliga",
        "changeUselg": "Myntkonto är tillgängligt",
        "changeUse": "Handelskonton tillgängliga",
        "microUse": "Sekunder kontrakt konto tillgängligt",
        "look": "Kolla upp",
        "chargeRecord": "Insättningsrekord",
        "status": "spårningsstatus",
        "noopen": "Denna funktion är inte tillgänglig ännu",
        "enterAdderses": "Vänligen ange uttagsadressen",
        "enterNum": "Vänligen ange uttagsbeloppet",
        "lessMin": "Det angivna uttagsbeloppet är mindre än minimivärdet",
        "tips01": "Sätt inte in några andra tillgångar till ovanstående adress, annars kommer tillgångarna inte att hämtas.",
        "tips02": "Insättningar stöder endast den enkla sändningsmetoden Insättningar med andra metoder (skicka alla) kan inte krediteras tillfälligt.",
        "beizhu": "Anmärkning",
        "pbeizhu": "Vänligen ange kommentarer",
        "add": "Lägg till",
        "addressList": "adresslista",
        "delete": "radera",
        "norec": "Inga uppgifter",
        "selectCurrency": "Välj valuta",
        "chainType": "kedjetyp",
        "commissionLibrary": "kommissionsbibliotek",
    },
    // 账户设置
    "set": {
        "mycode2": "min behörighetskod",
        "copycode2": "Kopiera auktoriseringskod",
        "uaccont": "Din kontosäkerhetsnivå:",
        "strong": "Kraftfull",
        "middle": "Mitten",
        "weak": "Svag",
        "min": "Låg",
        "heigh": "Hög",
        "complete": "Fyll i mer information för att säkerställa kontosäkerheten",
        "mycode": "min inbjudningskod",
        "copycode": "Kopiera inbjudningskoden",
        "bindphone": "Bind mobiltelefon",
        "bindemail": "Bind e-post",
        "loginpwd": "inloggningslösenord",
        "noBind": "Inte bunden",
        "binds": "bindande",
        "bind": "Gå till bindning",
        "binded": "Bunden",
        "net": "Internetkonton riskerar att bli stulen. Det rekommenderas att du ändrar ditt lösenord regelbundet för att skydda ditt kontos säkerhet.",
        "reset": "Revidera",
        "setPsw": "Ange ett transaktionslösenord för att säkerställa transaktionssäkerhet.",
        "goSet": "Gå till Inställningar",
        "bindAccount": "Bindbyteskonto",
        "duihuan": "Bind konto för att lösa in poäng",
        "copysuccess": "Kopierades framgångsrikt",
        "copyfail": "Kopieringen misslyckades",
        "recopy": "Vänligen kopiera igen",
        "account": "konto",
        "detail": "Detaljer",
        "val": "Belopp",
        "forgetPsw": "glöm lösenordet",
        "code": "Verifierings kod",
        "getCode": "få verifieringskod",
        "setPsw": "Välj lösenord",
        "enterPsw": "Ange lösenord Tack",
        "pswFalse": "Lösenorden som angetts två gånger är inkonsekventa",
        "enterPswagain": "Vänligen ange lösenordet igen",
        "jsetPsw": "Ställ in transaktionslösenord",
        "jenterPsw": "Vänligen ange transaktionslösenord",
        "jenterPswagain": "Vänligen ange transaktionslösenordet igen",
        "enterAccount": "Vänligen ange användarnamn",
        "enterCode": "vänligen ange verifieringskoden",
        "accountFalse": "Mobiltelefonen eller e-postkontot du angett följer inte reglerna!",
        "second": "sekunder",
        "sendCode": "Skicka verifieringskoden",
        "sendsucc": "Skickades framgångsfullt",
        "enterPswTwo": "Vänligen ange ditt lösenord två gånger",
        "rest": "Återstående",
        "yes": "ja",
        "no": "Nej",
        "yorn": "Om ett transaktionslösenord ska ställas in",
        "authenticate": "Vänligen utför identitetsverifiering",
    },
    // 身份认证
    "auth": {
        "identity": "Autentisering",
        "name": "namn",
        "idcard": "ID",
        "upimg": "Ladda upp ID-foton, den första är framsidan, den andra är baksidan och den tredje är den främre bilden med ID:t.",
        "submit": "Skicka in",
        "auditing": "under granskning...",
        "certified": "verifierad!",
        "pname": "Vänligen skriv in ditt namn",
        "pidcard": "Vänligen ange ditt ID-nummer",
        "pimg": "Ladda upp en bild på ditt ID!",
    },
    // 收款方式
    "seting": {
        "pmethod": "betalningsmetod",
        "truename": "verkliga namnet",
        "opening": "Bank namn",
        "bank": "Bankkortsnummer",
        "alipay": "Alipay-konto",
        "wename": "Smeknamn för WeChat",
        "wechat": "WeChat-konto",
        "pname": "vänligen ange ditt riktiga namn",
        "popen": "Vänligen ange namnet på banken där kontot har öppnats",
        "pcard": "Ange bankkortsnummer",
        "palipay": "Vänligen ange ditt Alipay-kontonummer",
        "pwname": "Vänligen ange ditt WeChat smeknamn",
        "pwaccount": "Ange ditt WeChat-konto",
        "pimg1": "WeChat betalningskod",
        "pimg2": "Alipay betalningskod",
    },
    // 帮助中心
    "news": {
        "seeall": "visa alla",
        "helpcenter": "Meddelandecenter",
        "back": "lämna tillbaka",
    },
    //登录
    "login": {
        "welcome": "Logga in tack",
        "phoneLogin": "Logga in med telefonen",
        "emailLogin": "E-postinloggning",
        "account": "konto",
        "psw": "Lösenord",
        "login": "Logga in",
        "nouser": "Inte en FutureInvestment-användare än?",
        "goRegister": "Registrera dig nu och börja handla på världens ledande handelsplattform för digitala tillgångar.",
        "registerFree": "Gratis registrering",
        "psw6": "Lösenordet får inte vara mindre än sex tecken",
    },
    "register": {
        "register": "Registrera",
        "phoneRegister": "Registrera din telefon",
        "emailRegister": "e-postregistrering",
        "country": "nationalitet",
        "enterPhone": "Vänligen ange telefonnummer",
        "enterEmail": "vänligen ange din e-post",
        "psw16": "Lösenordet måste vara mellan 6-16 tecken",
        "inviteCode": "Inbjudningskod",
        "sel": "Nödvändig",
        "sel2": "Frivillig",
        "read": "Jag har läst och håller med",
        "xieyi": "\"Användaravtal\"",
        "tips01": "Nationalitetsinformation kan inte ändras efter registrering, så se till att välja den sanningsenligt.",
        "tips02": "Verifieringse-postmeddelanden kan av misstag identifieras som spam, så kontrollera dem noggrant.",
        "tips03": "Behåll ditt FutureInvestment-kontonummer och inloggningslösenord ordentligt.",
        "tips04": "Använd inte samma inloggningslösenord som på andra webbplatser.",
        "emailFalse": "E-postmeddelandet du angav följer inte reglerna",
    },
    // 积分兑换
    "jc": {
        "title": "JC utbyte digitala tillgångar BEAU plattform",
        "balance": "Balans",
        "cannum": "Tillgänglig JC kvantitet",
        "new": "Senaste priset",
        "exnum": "Byt kvantitet",
        "pnum": "Vänligen ange JC-antal",
        "canex": "Kvantitet BEAU som kan lösas in",
        "exnow": "Lös in nu",
        "record": "Utbytesrekord",
        "exprice": "bytespris",
        "extime": "Inlösentid",
        "not": "Inte tillräckligt med poäng tillgängliga",
        "usenum": "Använd JC kvantitet",
        "out": "sluta",
        "gotd": "gå handel",
    },
    "coin": {
        "manage": "finanshantering",
        "total": "total insättning",
        "bao": "insättning skatt",
        "mybao": "Min insättning",
        "profit": "Beräknad intäkt",
        "cuntime": "Insättningstid för mynt",
        "endtine": "Utgångsdatum",
    },
    "team": {
        "myteam": "mitt lag",
        "ztnum": "Antal direktremisser",
        "activenum": "Antal aktiva medlemmar i laget",
        "profit": "inkomst",
        "back": "Rabatt",
        "cblog": "Insättningsrekord",
        "bblog": "myntrekord",
    },
    // k线
    "kline": {
        "text1": "tidsdelning",
        "text2": "1 minut",
        "text3": "5 minuter",
        "text4": "15 minuter",
        "text5": "30 minuter",
        "text6": "1 timme",
        "text7": "1 dag",
        "text8": "1 vecka",
        "text9": "januari",
    },
    // 秒合约
    "miscro": {
        "trade": "i transaktion",
        "buyPrice": "köp pris",
        "finshPrice": "slutgiltigt pris",
        "loss": "Beräknad vinst och förlust",
        "times": "Nedräkning",
        "mode": "handelsmodell",
        "num": "Öppen position kvantitet",
        "rate": "Lönsamhet",
        "up": "Köpa upp",
        "down": "Köp eller sälj",
        "openNum": "Ange öppningsantal",
        "success": "framgångsrikt beställd!",
        "c2c": "C2C-konto",
        "complaint": "Förslag",
        "reply": "Skriv ner din fråga så återkommer vi till dig så snart som möjligt...",
        "complaintList": "Klagomålsförslagslista",
        "complaintReply": "svar:",
        "complaintDescription": "Ange en beskrivning",
        "resetpwd": "ändra lösenord",
        "foundedOn": "Inbyggd",
        "commonProblem": "vanligt problem",
        "statement": "förklara",
        "about": "Om FutureInvestment",
        "everyone": "Alla är VD",
        "program": "FutureInvestment Global Partner Program",
        "program2": "Partnerprogram",
        "myMine": "Min min:",
        "friend": "Mina gruvvänner:",
        "myRank": "Min nivå:",
        "accumulated": "Kumulativ provision:",
        "copyLinks": "Kopiera marknadsföringslänk",
        "moneyVein": "Att förvandla anslutningar till pengar",
        "example": "Exempel på kommissionen",
        "enterQuantity": "Vänligen ange antal",
        "contractBalance": "Saldo på kontraktskonto",
        "submitRepeatedly": "Inlämning pågår, vänligen skicka inte upprepade gånger",
        "alipayCode": "Alipay betalningskod",
        "wechatCode": "WeChat betalningskod",
        "realPrice": "realtidspris",
        "currencyExchange": "Lös in valuta",
        "currencyExchangeIn": "Lös in valuta",
        "cashableBalance": "Saldo tillgängligt för inlösen:",
        "minimumCashable": "Minsta inlösenbelopp:",
        "maximumCashable": "Maximal utbetalning:",
        "automaticallys": "När du har klickat på Lös in kommer den att slutföras automatiskt och kan inte returneras.",
        "match": "utbyta",
        "title": "Utbyte av tillgångar",
        "holdAssets": "innehar tillgångar",
        "dangerousCurrency": "Håll riskmynt",
        "convertibleQuantity": "Inlösbar kvantitet",
        "currencyExchanges": "Valutaväxelkurs:",
        "insuranceCurrency": "Försäkringsvaluta",
        "insuranceType": "försäkringstyp",
        "contractAsset": "Avtalstillgångsförsäkringsbelopp",
        "warehouses": "Antal försäkringslager",
        "availableAssets": "Tillgängliga tillgångar:",
        "insuredAssets": "Försäkrade tillgångar:",
        "insuredAssets1": "Försäkrade tillgångar",
        "insuranceAssets": "Försäkringstillgångar:",
        "purchaseInsurance": "Prenumeration på försäkring",
        "insuranceClaims": "försäkringskrav",
        "insuranceCancellation": "Uppsägning av försäkring",
        "coinWallet": "Försäkringsmyntplånbok",
        "bmbWallet": "HAP plånbok",
        "cumulativeCoins": "Ackumulerade mynt:",
        "availableQuantity": "Tillgänglig kvantitet:",
        "rawCurrency": "Myntrekord",
        "contractInsurance": "avtalsförsäkring",
        "tenThousand": "Tio tusen",
        "runningLow": "Otillräcklig balans",
        "purchase": "Kvantiteten du angett överensstämmer inte med reglerna och din köpgräns ligger inom",
        "reach": "till",
        "between": "mellan",
        "onlyEnter": "Du kan bara gå in",
        "integersBetween": "heltal mellan",
        "notReturned": "Genom att klicka för att teckna försäkring anses du ha förstått försäkringsavtalet i detalj och samtyckt till att försäkringslagret automatiskt träder i kraft och inte kan returneras.",
        "settled": "När förlusten av försäkrade tillgångar gör det omöjligt att göra en beställning kommer försäkringskrav att göras",
        "profitable": "gånger när tillgången går med vinst",
        "terminated": ", upphör försäkringen automatiskt.",
        "automatically": "När de försäkrade tillgångarna förlorar 50% måste du ansöka om en försäkringsskada, annars kommer du inte att kunna handla dag Om du överskrider två gånger betalas ersättningen ut den t+1.",
        "termination": "Efter att försäkringsavtalet har sagts upp kan de försäkrade tillgångarna lösas in.",
        "just": "bara",
        "back": "motsatt",
        "lowestNumber": "Minsta kvantitet är",
        "confirmExchange": "Bekräfta inlösen?",
        "contractValuation": "Kontraktskonto tillgångsvärdering",
        "secondValuation": "Andra kontraktskontots tillgångsvärdering",
        "falshValuation": "Omedelbar värdering av tillgångar för utbyte av konton",
        "c2cValuation": "C2C-kontotillgångsvärdering",
        "recordWithdrawal": "Insättnings- och uttagsregister",
        "category": "kategori",
        "safetyCenter": "Säkerhetscenter",
        "safeText1": "Används för att ta emot verifierings-SMS vid registrering, ändring av lösenord och inställning av säkerhet",
        "safeText2": "Internetkonton riskerar att bli stulen. Det rekommenderas att du ändrar ditt lösenord regelbundet för att skydda ditt kontos säkerhet.",
        "flashTrading": "Flash transaktion",
        "assetCenter": "Tillgångscenter",
        "promotionCode": "Min kampanjkod",
        "loginAgain": "Inloggningen har löpt ut, vänligen logga in igen",
        "text10": "HAP-baserade realtidspriser",
        "text11": "Behörighetskod",
    }
}
