export default {
    "language": "عربي",
    // 导航
    "zdy": {
        "tjsq": "قدم الطلب",
        "czsl": "إعادة شحن الكمية",
        "enterczsl": "الرجاء إدخال مبلغ إعادة الشحن",
        "qbdz": "عنوان المحفظة",
        "enterqbdz": "الرجاء إدخال عنوان المحفظة",
        "sftjmcj": "ما إذا كان سيتم تقديم سعر بيع الطلب",
        "sftjmrj": "ما إذا كان سيتم تقديم سعر شراء الطلب أم لا",
        "proloss": "جني الربح ووقف الخسارة",
    },
    "header": {
        "home": "الصفحة الأمامية",
        "markets": "يقتبس",
        "fiat": "تداول العملات الورقية",
        "c2c": "معاملة C2C",
        "exchange": "تداول العملات المشفرة",
        "lever": "تداول العقود",
        "micro": "تداول العقد الثاني",
        "myshop": "متجري",
        "assets": "أصولي",
        "help": "مركز المساعدة",
        "login": "تسجيل الدخول",
        "sign": "يسجل",
        "accountSet": "إعدادت الحساب",
        "tradeLog": "سجل المعاملات",
        "receiveSet": "إعدادات الدفع",
        "identify": "المصادقة",
        "jchange": "تحويل العملات",
        "logout": "خروج",
        "themedark": "الوضع الليلي",
        "themeday": "وضع اليوم",
    },
    "frame": {
        "a": "لديك حالة طلب سحب جديدة، يرجى النقر لمشاهدتها",
        "b": "يلغي",
        "c": "بالتأكيد",
        "con": "عزيزي المستخدمين، لأن هناك العديد من الأعضاء يتداولون في مركز التداول. ",
    },
    // 登录注册
    "lg": {
        "login": "تسجيل الدخول",
        "register": "يسجل",
    },
    // 首页
    "home": {
        "vol": "كمية 24 ساعة",
        "c01": "منصة تداول الأصول الرقمية الرائدة في العالم",
        "c02": "توفير خدمات آمنة وموثوقة لتداول الأصول الرقمية وإدارة الأصول لملايين المستخدمين في أكثر من 130 دولة حول العالم",
        "c03": "آمنة وجديرة بالثقة",
        "c04": "5 سنوات من الخبرة في مجال الخدمات المالية للأصول الرقمية",
        "c05": "بنية موزعة احترافية ونظام هجوم مضاد لـ DDOS",
        "c06": "التخطيط البيئي العالمي",
        "c07": "تم إنشاء مراكز خدمات تجارية محلية في العديد من البلدان",
        "c08": "قم بإنشاء نظام بيئي blockchain يدمج نماذج أعمال متعددة",
        "c09": "المستخدم أولا",
        "c10": "إنشاء آلية تعويض متقدمة",
        "c11": "إنشاء صندوق حماية المستثمر",
        "c12": "منصة تداول متعددة المنصات في أي وقت وفي أي مكان",
        "c13": "يغطي منصات متعددة بما في ذلك iOS وAndroid وWindows، ويدعم وظائف الأعمال الكاملة",
        "sweep": "مسح الرمز للتحميل",
        "android": "ذكري المظهر",
        "pinput": "الرجاء إدخال البريد الإلكتروني الخاص بك أو رقم الهاتف المحمول",
        "atrade": "قم بتسجيل حساب FutureInvestment Global Station وابدأ رحلة التداول الخاصة بك",
        "download": "تحميل التطبيق",
        "ios": "تحميل دائرة الرقابة الداخلية",
        "androidDownload": "تحميل اندرويد",
        companyoverview1: "آمن",
        companyoverview2: "خبرة 5 سنوات في الخدمات المالية للأصول الرقمية، بنية موزعة احترافية ونظام مضاد لهجوم DDoS.",
        companyoverview3: "موثوق",
        companyoverview4: "سريع"
    },
    // 底部
    "foo": {
        "about": "معلومات عنا",
        "support": "دعم المستخدم",
        "contact": "اتصل بنا",
        "qq": "خدمة العملاء QQ",
        "email": "تواصل بالبريد الاكتروني",
    },
    // 行情
    "market": {
        "handicap": "عائق",
        "exchange": "عملات معدنية",
        "selfmarket": "خياري",
        "allmarket": "جميع الاقتباسات",
        "symbol": "زوج التداول",
        "newprice": "أحدث السعر",
        "change": "يزيد",
        "highprice": "اغلى سعر",
        "lowprice": "أقل سعر",
        "vol": "كمية 24 ساعة",
    },
    // 公共交易
    "td": {
        "buy": "يشتري",
        "sell": "يبيع",
        "currency": "عملة",
        "num": "كمية",
        "time": "وقت",
        "limit": "حد",
        "price": "سعر الوحدة",
        "method": "طريقة الدفع او السداد",
        "total": "مبلغ مقطوع",
        "inwant": "الرجاء إدخال المطلوب",
        "do": "العمل",
        "more": "تحميل المزيد",
        "nomore": "لا أكثر",
        "nodata": "لايوجد بيانات",
        "trade": "تجارة",
        "buynum": "كمية الشراء",
        "sellout": "يبيع",
        "buyin": "يشتري",
        "all": "الجميع",
        "allbuy": "شراء الكل",
        "allsell": "بيع كل شيء",
        "buytotal": "الرجاء إدخال المبلغ الإجمالي الذي ترغب في شرائه",
        "selltotal": "الرجاء إدخال المبلغ الإجمالي الذي تريد بيعه",
        "buyallnum": "الرجاء إدخال الكمية التي ترغب بشرائها",
        "sellallnum": "الرجاء إدخال الكمية التي تريد بيعها",
        "tradeTotal": "إجمالي مبلغ الصفقة",
        "doceil": "الإلغاء تلقائيًا بعد ثوانٍ",
        "place": "تقديم الطلب",
        "waitpay": "في انتظار الدفع",
        "paycode": "رمز الدفع",
        "finished": "مكتمل",
        "ceiled": "ألغيت",
        "payed": "مدفوع بالفعل",
        "nofinish": "تم التراجع عنه",
        "buyer": "مشتر",
        "seller": "تاجر",
        "callWay": "معلومات الاتصال",
        "placeTime": "وقت الطلب",
        "renum": "رقم المرجع",
        "canceil": "يلغي",
        "confirm": "يتأكد",
        "pwd": "الرجاء إدخال كلمة مرور المعاملة",
        "coincode": "وحدة العملة",
        "pcoin": "الرجاء تحديد وحدة العملة",
        "setTradePwd": "تعيين كلمة مرور المعاملة؟",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "سجل الطلب",
        "fatdLog": "سجلات معاملات العملة القانونية",
        "shoper": "تاجر",
        "faLog": "سجلات معاملات العملة القانونية",
        "tdType": "نوع المعاملة",
        "odStatus": "حالة الطلب",
        "pwaitPay": "يرجى الانتظار لدفع المشتري",
        "odFinish": "تم اكتمال الطلب",
        "odCeil": "تم إلغاء الطلب",
        "odPay": "لقد تم الدفع، يرجى التحقق من معلومات الدفع بعناية",
        "tdCeil": "إلغاء الصفقة",
        "pCeil": "إذا كنت قد دفعت للبائع بالفعل، فيرجى عدم إلغاء المعاملة.",
        "paySure": "تأكيد الدفعة",
        "pdopay": "يرجى التأكد من أنك دفعت للبائع",
        "badClick": "تؤدي النقرات الضارة إلى تجميد الحساب مباشرة",
        "receivePay": "تأكيد الدفعة",
        "payReceive": "يرجى التأكد من أن المشتري قد دفع لك",
        "receivePays": "تأكيد استلام الدفعة",
        // LegalPay
        "payPlease": "يرجى الدفع",
        "tdPrice": "سعر وحدة المعاملة",
        "tdNum": "عدد المعاملات",
        "payinfo": "معلومات الدفع",
        "banknum": "حساب البنك",
        "realName": "الاسم الفعلي",
        "shoperZname": "فرع التاجر",
        "shoperNum": "حساب تجارى",
        "odCeil": "الغاء الطلب",
        "imPay": "لقد قمت بالدفع، انقر للتأكيد",
        // LegalSeller
        "register_time": "وقت التسجيل",
        "odtotal": "ترتيب التجميع",
        "odmonth": "اكتمل الطلب في يوم 30",
        "odfinish": "اكمل الطلب",
        "odrate": "معدل الإنجاز",
        "submit": "يطلق",
        "phone": "الهاتف الخلوي",
        "mysell": "بلدي للبيع",
        "mybuy": "شراء بلدي",
        "realAuth": "تم التحقق",
        "advAuth": "شهادة متقدمة",
        "linebuy": "شراء عبر الإنترنت",
        "linesell": "للبيع على الانترنت",
        "tipPay": "يرجى الاتصال بالتاجر خلال 24 ساعة للدفع، وإذا تجاوزت 24 ساعة، فسيتم إلغاؤه تلقائيًا.",
        "notlow": "لا يمكن أن تذهب أقل من الحد الأدنى",
        "nothigh": "لا يمكن تجاوز الحد الأقصى",
        "notnum": "لا يمكن أن تتجاوز الكمية القصوى",
        // LegalShopDetail
        "type": "يكتب",
        "status": "ولاية",
        "seeOrder": "تحقق من الطلب",
        "withdraw": "ينسحب",
        "abnormal": "غير طبيعي",
        "lowershelf": "تمت إزالتها من الرفوف",
        "protection": "حماية الحقوق قيد التقدم",
        "apply": "التقدم بطلب لحماية الحقوق",
        "psType": "الرجاء اختيار النوع",
        "pselect": "اختر من فضلك",
        "alipay": "أليباي",
        "wechat": "وي شات",
        "bank": "بطاقة مصرفية",
        "minNum": "الحد الأدنى لحجم الصفقة",
        "maxNum": "الحد الأقصى لحجم التداول",
        "wantBuy": "أريد شراء",
        "pnums": "الرجاء إدخال الكمية",
        "pmin": "الرجاء إدخال الحد الأدنى لحجم المعاملة",
        "pmax": "الرجاء إدخال الحد الأقصى لحجم المعاملة",
        "pnot": "لا يمكن أن يكون الحد الأقصى لحجم المعاملة أقل من الحد الأدنى لحجم المعاملة",
        // MyLegalShops
        "myshops": "متجري",
        "name": "اسم",
        "fiatSub": "العملة القانونية",
        "shoper_balance": "رصيد التاجر",
        "enterShop": "أدخل متجرا",
        // shop_legal_pay_detail
        "payedWait": "تم الدفع، في انتظار التحقق والتأكيد",
        "yes": "نعم",
        "no": "لا",
        "sd": "قفل التوازن",
        "journal": "سجل الرصيد",
        "business": "تاجر",
        "user": "مستخدم",
        "backdone": "انسحبت",
        "busbalance": "رصيد التاجر",
        "userbalance": "رصيد المستخدم",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C التي نشرتها لي",
        "myTrade": "C2C لمعاملتي",
        "and": "يمين",
        "balance": "توازن",
        "buynum": "حجم الشراء",
        "sellnum": "حجم البيع",
        "bankTransfer": "التحويل المصرفي",
        "payim": "يجب أن تدفع شخصيا",
        "pbuyNum": "الرجاء إدخال كمية الشراء",
        "pprice": "الرجاء إدخال سعر الوحدة",
        "payway": "الرجاء تحديد طريقة الدفع",
        "psellNum": "الرجاء إدخال كمية البيع",
        "suerePlace": "أكد الطلب؟",
        "detail": "تفاصيل",
        "payinfo": "معلومات الدفع",
        "Payee": "المستفيد",
        "total": "مبلغ مقطوع",
        "name": "اسم",
        "cardnum": "رقم البطاقة",
        "receivePay": "تأكيد استلام الدفعة",
        "ceilConfirm": "تأكيد إلغاء المعاملة؟",
        "paySeller": "يرجى التأكد من قيامك بالدفع للبائع، حيث تؤدي النقرات الضارة إلى تجميد حسابك.",
        "payBuyer": "يرجى التأكد من أنك قد تلقيت معلومات الدفع من المشتري",
        "account": "حساب",
        "sellerPay": "يرجى التأكد من أن المشتري قد دفع لك؟",
        "payattion": "يرجى الدفع للبائع في أقرب وقت ممكن بعد تأكيد الدفع، وستؤدي النقرات الضارة إلى تجميد الحساب مباشرة.",
        "log": "اكتمال الطلب c2c",
    },
    // 合约
    "lever": {
        "nowentrust": "النظام الحالي",
        "hisentrust": "اللجنة التاريخية",
        "lvchi": "مواقف العقد",
        "all": "الجميع",
        "or": "أو",
        "gotrade": "بدء التداول",
        "tdrecord": "سجل المعاملات",
        "dealed": "الاتفاق تم",
        "notdeal": "لا يوجد معاملة",
        "loading": "تحميل...",
        "entotal": "المبلغ الإجمالي الموكل",
        "canuse": "متاح",
        "std": "معاملة سعر السوق",
        "xtd": "الحد من التجارة",
        "pprice": "الرجاء إدخال السعر",
        "phand": "الرجاء إدخال حجم الكثير الشراء",
        "phandsell": "الرجاء إدخال حجم كمية البيع",
        "pwd": "كلمة المرور",
        "ppwd": "الرجاء إدخال كلمة مرور المعاملة",
        "psw": "عملية كلمة المرور",
        "equal": "متساوي",
        "times": "مرات",
        "timed": "عديد",
        "hand": "يُسلِّم",
        "hands": "عدد من الخطوات",
        "ptimes": "الرجاء تحديد متعددة",
        "phands": "الرجاء تحديد حجم الكمية",
        "contractVal": "القيمة السوقية للعقد",
        "bail": "هامِش",
        "canBail": "الهامش المتاح",
        "charge": "رسوم خدمة المعاملات",
        "domore": "شراء (الذهاب لفترة طويلة)",
        "doshort": "بيع (قصير)",
        "dmore": "ذهاب لفتره طويلة",
        "dshort": "قصير",
        "sureOd": "أكد الطلب",
        "noless": "لا يمكن أن يكون حجم اللوت أقل من",
        "nomore": "لا يمكن أن يكون حجم اللوت أعلى من",
        "tdnum": "حجم التداول",
        "risk": "معدل المخاطرة",
        "allloss": "إجمالي الربح والخسارة للمراكز",
        "onehouse": "إغلاق المركز بنقرة واحدة",
        "type": "يكتب",
        "entrustPrice": "سعر الطلب",
        "openPrice": "سعر الافتتاح",
        "nowPrice": "السعر الحالي",
        "styPrice": "سعر جني الأرباح",
        "stsPrice": "سعر وقف الخسارة",
        "openTime": "وقت مفتوح",
        "closeTime": "وقت الإغلاق",
        "rate": "رسوم المعالجة",
        "nightFee": "رسوم بين عشية وضحاها",
        "loss": "الربح والخسارة",
        "setloss": "تعيين جني الأرباح ووقف الخسارة",
        "expectProfit": "الربح المتوقع",
        "expectLoss": "الخسارة المتوقعة",
        "allClose": "إغلاق كافة المواقف",
        "moreClose": "إغلاق الطلبات الطويلة فقط",
        "nullClose": "إغلاق الطلبات القصيرة فقط",
        "sureClose": "تأكيد إغلاق الموقف؟",
        "thanZearo": "يجب أن تكون مجموعة القيمة أكبر من 0",
        "listin": "طلب معلق",
        "tdin": "في الصفقة",
        "closein": "إغلاق الموقف",
        "closed": "الموقف مغلق",
        "revoked": "إلغاء - فسخ",
        "revokeOrder": "هل أنت متأكد أنك تريد إلغاء الطلب؟",
        "ping": "موقف قريب",
        "revoke": "الغاء الطلب",
        "sureping": "هل أنت متأكد من إغلاق الموقف؟",
        "thanzone": "يجب أن تكون مجموعة القيمة أكبر من 0",
    },
    // 币币
    "cuy": {
        "direction": "اتجاه",
        "total": "المجموع",
        "price": "سعر",
        "sell": "يبيع",
        "buy": "شراء",
        "allStation": "تداول الموقع بالكامل",
        "buyPrice": "سعر الشراء",
        "buynum": "حجم الشراء",
        "sellPrice": "سعر البيع",
        "sellnum": "حجم البيع",
        "tdPrice": "حجم الصفقة",
        "or": "أو",
        "tdStart": "بدء التداول",
        "pbPrice": "الرجاء إدخال سعر الشراء",
        "pbNum": "الرجاء إدخال مبلغ الشراء",
        "psPrice": "الرجاء إدخال سعر البيع",
        "psNum": "الرجاء إدخال مبلغ البيع",
        "fixPrice": "الحد من التجارة",
        "Mtrans": "معاملة سعر السوق",
        "available": "متاح",
        "revoke": "يلغي",
        "loading": "تحميل",
        "confirmCancel": "هل أنت متأكد أنك تريد إلغاء؟",
        "confirm": "بالتأكيد",
        "cancel": "يلغي",
        "nomore": "لا مزيد من البيانات",
        "evelPrice": "متوسط ​​سعر الصفقة",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "هل تريد تأكيد النقل؟",
        "assets": "أصول",
        "currency_account": "حساب العملة",
        "lever_account": "حساب العقد",
        "fince_account": "حساب فيات",
        "change_account": "حساب الصفقة",
        "micro_account": "حساب العقد الثاني",
        "miscroAccount": "حساب العقد الثاني",
        "assets_hua": "تحويل الأموال",
        "all_assets": "إجمالي الأصول المحولة",
        "currency": "عملة",
        "canuse": "متاح",
        "frezz": "تجميد",
        "conversion": "تحويلها",
        "charging": "إيداع العملات المعدنية",
        "withdraw": "سحب العملات المعدنية",
        "address_bind": "ربط عنوان السحب",
        "address_withdraw": "إدارة عنوان سحب العملة",
        "record": "تفاصيل",
        "tbrecord": "سجل سحب العملة",
        "address_charge": "عنوان الإيداع",
        "address_width": "عنوان السحب",
        "copy": "ينسخ",
        "ercode": "رمز الاستجابة السريعة",
        "reminder": "نصائح لطيفة",
        "notcharge": "يرجى عدم إيداع أي أموال على العنوان المذكور أعلاه.",
        "ornone": "وإلا فلن يتم استرداد الأصول",
        "support": "الودائع تدعم فقط طريقة الإرسال البسيطة، ولا يمكن إضافة الودائع باستخدام طرق أخرى (إرسال الكل) مؤقتًا.",
        "minnum": "الحد الأدنى لمبلغ السحب",
        "ratenum": "رسوم المعالجة",
        "havenum": "وصول الكمية",
        "from": "من",
        "to": "إلى",
        "transfer": "تحويل",
        "tfnum": "نقل الكمية",
        "ptfnum": "الرجاء إدخال الحد الأدنى",
        "dtfnum": "عدد التحويلات",
        "canbalance": "الرصيد المتوفر",
        "sureConfirm": "تأكيد النقل",
        "moneyRecord": "السجلات المالية",
        "pNum": "الرجاء إدخال مبلغ التحويل",
        "leverUse": "حسابات العقود متوفرة",
        "legalUse": "حسابات فيات متاحة",
        "changeUselg": "حساب العملة متاح",
        "changeUse": "حسابات التداول المتاحة",
        "microUse": "حساب عقد الثواني متاح",
        "look": "يفحص",
        "chargeRecord": "سجل الودائع",
        "status": "حالة التتبع",
        "noopen": "هذه الوظيفة غير متوفرة بعد",
        "enterAdderses": "الرجاء إدخال عنوان السحب",
        "enterNum": "الرجاء إدخال مبلغ السحب",
        "lessMin": "مبلغ السحب الذي تم إدخاله أقل من الحد الأدنى للقيمة",
        "tips01": "يرجى عدم إيداع أي أصول أخرى على العنوان المذكور أعلاه، وإلا فلن يتم استرداد الأصول.",
        "tips02": "الودائع تدعم فقط طريقة الإرسال البسيطة، ولا يمكن إضافة الودائع باستخدام طرق أخرى (إرسال الكل) مؤقتًا.",
        "beizhu": "ملاحظة",
        "pbeizhu": "الرجاء إدخال الملاحظات",
        "add": "اضف إليه",
        "addressList": "قائمة العناوين",
        "delete": "يمسح",
        "norec": "لا تسجيلات",
        "selectCurrency": "الرجاء تحديد العملة",
        "chainType": "نوع السلسلة",
        "commissionLibrary": "مكتبة اللجنة",
    },
    // 账户设置
    "set": {
        "mycode2": "رمز الترخيص الخاص بي",
        "copycode2": "نسخ رمز التفويض",
        "uaccont": "مستوى أمان حسابك:",
        "strong": "قوي",
        "middle": "وسط",
        "weak": "ضعيف",
        "min": "قليل",
        "heigh": "عالي",
        "complete": "أكمل المزيد من المعلومات لضمان أمان الحساب",
        "mycode": "رمز الدعوة الخاص بي",
        "copycode": "انسخ رمز الدعوة",
        "bindphone": "ربط الهاتف المحمول",
        "bindemail": "ربط البريد الإلكتروني",
        "loginpwd": "كلمة سر الدخول",
        "noBind": "غير ملزمة",
        "binds": "ربط",
        "bind": "اذهب إلى الربط",
        "binded": "مرتبط ب",
        "net": "حسابات الإنترنت معرضة لخطر السرقة. يوصى بتغيير كلمة المرور الخاصة بك بانتظام لحماية أمان حسابك.",
        "reset": "يراجع",
        "setPsw": "قم بتعيين كلمة مرور للمعاملة لضمان أمان المعاملة.",
        "goSet": "اذهب للاعدادات",
        "bindAccount": "ربط حساب الصرف",
        "duihuan": "ربط الحساب لاستبدال النقاط",
        "copysuccess": "تم النسخ بنجاح",
        "copyfail": "فشل النسخ",
        "recopy": "يرجى النسخ مرة أخرى",
        "account": "حساب",
        "detail": "تفاصيل",
        "val": "كمية",
        "forgetPsw": "ننسى كلمة المرور",
        "code": "رمز التحقق",
        "getCode": "الحصول على رمز التحقق",
        "setPsw": "ضبط كلمة السر",
        "enterPsw": "الرجاء إدخال كلمة المرور",
        "pswFalse": "كلمات المرور التي تم إدخالها مرتين غير متناسقة",
        "enterPswagain": "الرجاء إدخال كلمة المرور مرة أخرى",
        "jsetPsw": "تعيين كلمة مرور المعاملة",
        "jenterPsw": "الرجاء إدخال كلمة مرور المعاملة",
        "jenterPswagain": "الرجاء إدخال كلمة مرور المعاملة مرة أخرى",
        "enterAccount": "الرجاء إدخال اسم المستخدم",
        "enterCode": "الرجاء إدخال رمز التحقق",
        "accountFalse": "الهاتف المحمول أو حساب البريد الإلكتروني الذي أدخلته لا يتوافق مع القواعد!",
        "second": "ثانية",
        "sendCode": "أرسل رمز التحقق",
        "sendsucc": "أرسل بنجاح",
        "enterPswTwo": "الرجاء إدخال كلمة المرور الخاصة بك مرتين",
        "rest": "متبقي",
        "yes": "نعم",
        "no": "لا",
        "yorn": "ما إذا كان سيتم تعيين كلمة مرور للمعاملة",
        "authenticate": "يرجى إجراء التحقق من الهوية",
    },
    // 身份认证
    "auth": {
        "identity": "المصادقة",
        "name": "اسم",
        "idcard": "بطاقة تعريف",
        "upimg": "يرجى تحميل صور الهوية، الأولى هي الأمامية والثانية هي الخلفية والثالثة هي الصورة الأمامية التي تحمل الهوية.",
        "submit": "يُقدِّم",
        "auditing": "قيد المراجعة...",
        "certified": "تم التحقق!",
        "pname": "من فضلك اكتب اسمك",
        "pidcard": "الرجاء إدخال رقم الهوية الخاص بك",
        "pimg": "يرجى تحميل صورة لبطاقة الهوية الخاصة بك!",
    },
    // 收款方式
    "seting": {
        "pmethod": "طريقة الدفع او السداد",
        "truename": "الاسم الفعلي",
        "opening": "اسم البنك",
        "bank": "رقم البطاقة المصرفية",
        "alipay": "حساب علي باي",
        "wename": "لقب وي شات",
        "wechat": "حساب وي شات",
        "pname": "الرجاء إدخال اسمك الحقيقي",
        "popen": "الرجاء إدخال اسم البنك الذي تم فتح الحساب فيه",
        "pcard": "الرجاء إدخال رقم البطاقة المصرفية",
        "palipay": "الرجاء إدخال رقم حساب Alipay الخاص بك",
        "pwname": "الرجاء إدخال لقب WeChat الخاص بك",
        "pwaccount": "الرجاء إدخال حساب WeChat الخاص بك",
        "pimg1": "رمز الدفع WeChat",
        "pimg2": "رمز الدفع علي باي",
    },
    // 帮助中心
    "news": {
        "seeall": "عرض الكل",
        "helpcenter": "مركز اعلانات",
        "back": "يعود",
    },
    //登录
    "login": {
        "welcome": "سجل الدخول من فضلك",
        "phoneLogin": "تسجيل الدخول بالهاتف",
        "emailLogin": "تسجيل الدخول بالبريد الإلكتروني",
        "account": "حساب",
        "psw": "كلمة المرور",
        "login": "تسجيل الدخول",
        "nouser": "لست مستخدم FutureInvestment حتى الآن؟",
        "goRegister": "سجل الآن وابدأ التداول على منصة تداول الأصول الرقمية الرائدة عالميًا.",
        "registerFree": "تسجيل مجاني",
        "psw6": "لا يمكن أن تكون كلمة المرور أقل من ستة أحرف",
    },
    "register": {
        "register": "يسجل",
        "phoneRegister": "سجل هاتفك",
        "emailRegister": "تسجيل البريد الإلكتروني",
        "country": "جنسية",
        "enterPhone": "الرجاء إدخال رقم الهاتف",
        "enterEmail": "يرجى إدخال البريد الإلكتروني الخاص بك",
        "psw16": "يجب أن تكون كلمة المرور بين 6-16 حرفًا",
        "inviteCode": "شفرة الدعوة",
        "sel": "مطلوب",
        "sel2": "خياري",
        "read": "لقد قرأت وأوافق",
        "xieyi": "\"اتفاقية المستخدم\"",
        "tips01": "لا يمكن تعديل معلومات الجنسية بعد التسجيل، لذا تأكد من اختيارها بشكل صحيح.",
        "tips02": "قد يتم تحديد رسائل التحقق عبر البريد الإلكتروني عن طريق الخطأ على أنها رسائل غير مرغوب فيها، لذا يرجى التحقق منها بعناية.",
        "tips03": "يرجى الاحتفاظ برقم حساب FutureInvestment الخاص بك وكلمة المرور لتسجيل الدخول بشكل صحيح.",
        "tips04": "لا تستخدم نفس كلمة المرور المستخدمة في مواقع الويب الأخرى.",
        "emailFalse": "البريد الإلكتروني الذي أدخلته لا يتوافق مع القواعد",
    },
    // 积分兑换
    "jc": {
        "title": "منصة JC لتبادل الأصول الرقمية BEAU",
        "balance": "توازن",
        "cannum": "الكمية المتوفرة من جي سي",
        "new": "أحدث السعر",
        "exnum": "كمية الصرف",
        "pnum": "الرجاء إدخال كمية JC",
        "canex": "كمية BEAU التي يمكن استبدالها",
        "exnow": "استبدل الآن",
        "record": "سجل الصرف",
        "exprice": "سعر الصرف",
        "extime": "وقت الفداء",
        "not": "لا يوجد ما يكفي من النقاط المتاحة",
        "usenum": "استخدم كمية JC",
        "out": "يترك",
        "gotd": "اذهب للتجارة",
    },
    "coin": {
        "manage": "ادارة مالية",
        "total": "إجمالي الودائع",
        "bao": "إيداع الكنز",
        "mybao": "إيداعي",
        "profit": "الإيرادات المقدرة",
        "cuntime": "وقت إيداع العملة",
        "endtine": "تاريخ انتهاء الصلاحية",
    },
    "team": {
        "myteam": "فريقي",
        "ztnum": "عدد الإحالات المباشرة",
        "activenum": "عدد الأعضاء النشطين في الفريق",
        "profit": "دخل",
        "back": "الخصم",
        "cblog": "سجل الودائع",
        "bblog": "سجل العملة",
    },
    // k线
    "kline": {
        "text1": "وقت المشاركة",
        "text2": "1 دقيقة",
        "text3": "5 دقائق",
        "text4": "15 دقيقة",
        "text5": "30 دقيقة",
        "text6": "1 ساعة",
        "text7": "يوم 1",
        "text8": "أسبوع 1",
        "text9": "يناير",
    },
    // 秒合约
    "miscro": {
        "trade": "في الصفقة",
        "buyPrice": "سعر الشراء",
        "finshPrice": "السعر النهائي",
        "loss": "الربح والخسارة المقدرة",
        "times": "العد التنازلي",
        "mode": "نموذج التداول",
        "num": "كمية الموقف المفتوح",
        "rate": "الربحية",
        "up": "يشتري",
        "down": "شراء أو بيع",
        "openNum": "الرجاء إدخال كمية الافتتاح",
        "success": "تم الطلب بنجاح!",
        "c2c": "حساب C2C",
        "complaint": "اقتراحات",
        "reply": "يرجى كتابة سؤالك وسوف نقوم بالرد عليك في أقرب وقت ممكن...",
        "complaintList": "قائمة اقتراحات الشكوى",
        "complaintReply": "رد:",
        "complaintDescription": "الرجاء إدخال وصف",
        "resetpwd": "تغيير كلمة المرور",
        "foundedOn": "بنيت في",
        "commonProblem": "مشكلة شائعة",
        "statement": "يشرح",
        "about": "حول FutureInvestment",
        "everyone": "الجميع هو الرئيس التنفيذي",
        "program": "برنامج شركاء FutureInvestment العالمي",
        "program2": "برنامج الشريك",
        "myMine": "منجم:",
        "friend": "أصدقائي التعدين:",
        "myRank": "مستواي:",
        "accumulated": "العمولة التراكمية:",
        "copyLinks": "انسخ رابط الترويج",
        "moneyVein": "تحويل الاتصالات إلى أموال",
        "example": "مثال اللجنة",
        "enterQuantity": "الرجاء إدخال الكمية",
        "contractBalance": "رصيد حساب العقد",
        "submitRepeatedly": "جاري التقديم، يرجى عدم تكرار الإرسال",
        "alipayCode": "رمز الدفع علي باي",
        "wechatCode": "رمز الدفع WeChat",
        "realPrice": "السعر في الوقت الحقيقي",
        "currencyExchange": "تخليص العملة",
        "currencyExchangeIn": "تخليص العملة",
        "cashableBalance": "الرصيد المتاح للاسترداد:",
        "minimumCashable": "الحد الأدنى لمبلغ الاسترداد:",
        "maximumCashable": "الحد الأقصى للسحب:",
        "automaticallys": "بعد النقر فوق \"استرداد\"، سيتم إكماله تلقائيًا ولا يمكن إرجاعه.",
        "match": "تبادل",
        "title": "تبادل الأصول",
        "holdAssets": "عقد الأصول",
        "dangerousCurrency": "احتفظ بالعملات المعدنية الخطرة",
        "convertibleQuantity": "كمية قابلة للاسترداد",
        "currencyExchanges": "سعر صرف العملة:",
        "insuranceCurrency": "عملة التأمين",
        "insuranceType": "نوع التأمين",
        "contractAsset": "مبلغ التأمين على أصول العقد",
        "warehouses": "عدد مستودعات التأمين",
        "availableAssets": "الأصول المتاحة:",
        "insuredAssets": "الأصول المؤمن عليها:",
        "insuredAssets1": "الأصول المؤمن عليها",
        "insuranceAssets": "أصول التأمين:",
        "purchaseInsurance": "الاشتراك في التأمين",
        "insuranceClaims": "مطالبات التأمين",
        "insuranceCancellation": "إلغاء التأمين",
        "coinWallet": "محفظة عملات التأمين",
        "bmbWallet": "محفظة هاب",
        "cumulativeCoins": "العملات المتراكمة:",
        "availableQuantity": "الكمية متاحة:",
        "rawCurrency": "سجل العملة",
        "contractInsurance": "تأمين العقد",
        "tenThousand": "عشرة آلاف",
        "runningLow": "رصيد غير كاف",
        "purchase": "الكمية التي أدخلتها لا تتوافق مع القواعد، وحد الشراء الخاص بك يقع ضمنه",
        "reach": "إلى",
        "between": "بين",
        "onlyEnter": "يمكنك الدخول فقط",
        "integersBetween": "عدد صحيح بين",
        "notReturned": "بالضغط على الاشتراك في التأمين، يعتبر أنك قد فهمت عقد التأمين بالتفصيل ووافقت على أن مستودع التأمين سيدخل حيز التنفيذ تلقائيًا ولا يمكن إرجاعه.",
        "settled": "عندما يؤدي فقدان الأصول المؤمن عليها إلى عدم إمكانية تقديم طلب، سيتم تقديم مطالبات التأمين",
        "profitable": "الأوقات التي يحقق فيها الأصل ربحًا",
        "terminated": "، سيتم إنهاء التأمين تلقائيًا.",
        "automatically": "عندما تفقد الأصول المؤمن عليها 50%، يجب عليك تقديم طلب تأمين، وإلا فلن تتمكن من التداول. عندما يصل الربح إلى 100%، سيتم إنهاء التأمين تلقائياً ولا يُسمح لمستودع التأمين إلا بدفع تعويضين إذا تجاوزت مرتين، فسيتم دفع التعويض في t+1.",
        "termination": "بعد إنهاء عقد التأمين، يمكن استرداد الأصول المؤمن عليها. بعد إنهاء العقد، يعتبر ذلك بمثابة خرق للعقد ويتم تطهير مستودع التأمين تلقائيا.",
        "just": "فقط",
        "back": "عكس",
        "lowestNumber": "الحد الأدنى للكمية هو",
        "confirmExchange": "تأكيد الاسترداد؟",
        "contractValuation": "تقييم أصول حساب العقد",
        "secondValuation": "تقييم أصول حساب العقد الثاني",
        "falshValuation": "تقييم أصول حساب الصرف الفوري",
        "c2cValuation": "تقييم أصول حساب C2C",
        "recordWithdrawal": "سجلات الإيداع والسحب",
        "category": "فئة",
        "safetyCenter": "مركز أمني",
        "safeText1": "يُستخدم لتلقي رسائل التحقق النصية القصيرة عند التسجيل وتغيير كلمات المرور وإعداد الأمان",
        "safeText2": "حسابات الإنترنت معرضة لخطر السرقة. يوصى بتغيير كلمة المرور الخاصة بك بانتظام لحماية أمان حسابك.",
        "flashTrading": "صفقة فلاش",
        "assetCenter": "مركز الأصول",
        "promotionCode": "رمز الترويج الخاص بي",
        "loginAgain": "انتهت صلاحية تسجيل الدخول، يرجى تسجيل الدخول مرة أخرى",
        "text10": "الأسعار في الوقت الحقيقي على أساس HAP",
        "text11": "قانون التفويض",
    }
}
