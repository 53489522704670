export default {
    "language": "Tiếng Việt",
    // 导航
    "zdy": {
        "tjsq": "nộp đơn",
        "czsl": "Số lượng nạp lại",
        "enterczsl": "Vui lòng nhập số tiền nạp",
        "qbdz": "địa chỉ ví",
        "enterqbdz": "Vui lòng nhập địa chỉ ví",
        "sftjmcj": "Có nên gửi giá bán đơn đặt hàng hay không",
        "sftjmrj": "Có nên gửi giá mua của đơn đặt hàng hay không",
        "proloss": "Chốt lãi và dừng lỗ",
    },
    "header": {
        "home": "trang đầu",
        "markets": "Báo giá",
        "fiat": "Giao dịch tiền pháp định",
        "c2c": "Giao dịch C2C",
        "exchange": "Giao dịch tiền điện tử",
        "lever": "giao dịch hợp đồng",
        "micro": "giao dịch hợp đồng thứ hai",
        "myshop": "cửa hàng của tôi",
        "assets": "tài sản của tôi",
        "help": "Trung tâm trợ giúp",
        "login": "Đăng nhập",
        "sign": "đăng ký",
        "accountSet": "cài đặt tài khoản",
        "tradeLog": "nhật ký giao dịch",
        "receiveSet": "Cài đặt thanh toán",
        "identify": "Xác thực",
        "jchange": "Thu đổi ngoại tệ",
        "logout": "đăng xuất",
        "themedark": "Chế độ ban đêm",
        "themeday": "chế độ ngày",
    },
    "frame": {
        "a": "Bạn có trạng thái lệnh rút mới, vui lòng bấm vào để xem",
        "b": "Hủy bỏ",
        "c": "Chắc chắn",
        "con": "Kính gửi người dùng, vì có rất nhiều thành viên giao dịch trong trung tâm giao dịch. ",
    },
    // 登录注册
    "lg": {
        "login": "Đăng nhập",
        "register": "đăng ký",
    },
    // 首页
    "home": {
        "vol": "số lượng 24H",
        "c01": "Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới",
        "c02": "Cung cấp dịch vụ quản lý tài sản và giao dịch tài sản kỹ thuật số an toàn và đáng tin cậy cho hàng triệu người dùng tại hơn 130 quốc gia trên thế giới",
        "c03": "An toàn và đáng tin cậy",
        "c04": "5 năm kinh nghiệm trong lĩnh vực dịch vụ tài chính tài sản kỹ thuật số",
        "c05": "Kiến trúc phân tán và hệ thống chống tấn công DDOS chuyên nghiệp",
        "c06": "Sơ đồ sinh thái toàn cầu",
        "c07": "Các trung tâm dịch vụ thương mại địa phương đã được thành lập ở nhiều nước",
        "c08": "Tạo hệ sinh thái blockchain tích hợp nhiều hình thức kinh doanh",
        "c09": "Người dùng là trên hết",
        "c10": "Thiết lập cơ chế bồi thường tiên tiến",
        "c11": "Thành lập quỹ bảo vệ nhà đầu tư",
        "c12": "Giao dịch thiết bị đầu cuối đa nền tảng mọi lúc, mọi nơi",
        "c13": "Bao gồm nhiều nền tảng bao gồm iOS, Android và Windows, hỗ trợ đầy đủ các chức năng kinh doanh",
        "sweep": "Quét mã để tải về",
        "android": "Android",
        "pinput": "Vui lòng nhập email hoặc số điện thoại di động của bạn",
        "atrade": "Đăng ký tài khoản FutureInvestment Global Station và bắt đầu hành trình giao dịch của bạn",
        "download": "Tải ứng dụng",
        "ios": "Tải xuống iOS",
        "androidDownload": "Tải xuống Android",
        companyoverview1: "An toàn",
        companyoverview2: "5 năm kinh nghiệm trong lĩnh vực dịch vụ tài chính tài sản kỹ thuật số Kiến trúc phân tán chuyên nghiệp và Hệ thống chống tấn công DDoS.",
        companyoverview3: "Đáng tin cậy",
        companyoverview4: "Nhanh"
    },
    // 底部
    "foo": {
        "about": "về chúng tôi",
        "support": "Hỗ trợ người dùng",
        "contact": "liên hệ chúng tôi",
        "qq": "Dịch vụ khách hàng QQ",
        "email": "Địa chỉ email liên lạc",
    },
    // 行情
    "market": {
        "handicap": "Chấp",
        "exchange": "đồng xu",
        "selfmarket": "Không bắt buộc",
        "allmarket": "Tất cả các trích dẫn",
        "symbol": "cặp giao dịch",
        "newprice": "Giá mới nhất",
        "change": "Tăng",
        "highprice": "giá cao nhất",
        "lowprice": "giá thấp nhất",
        "vol": "số lượng 24H",
    },
    // 公共交易
    "td": {
        "buy": "Mua",
        "sell": "bán",
        "currency": "Tiền tệ",
        "num": "Số lượng",
        "time": "thời gian",
        "limit": "giới hạn",
        "price": "đơn giá",
        "method": "phương thức thanh toán",
        "total": "số tiền một lần",
        "inwant": "Vui lòng nhập mong muốn của bạn",
        "do": "vận hành",
        "more": "tải thêm",
        "nomore": "không còn nữa",
        "nodata": "Không có dữ liệu",
        "trade": "buôn bán",
        "buynum": "Số lượng mua",
        "sellout": "bán",
        "buyin": "Mua",
        "all": "tất cả",
        "allbuy": "Mua hết",
        "allsell": "bán tất cả",
        "buytotal": "Vui lòng nhập tổng số tiền bạn muốn mua",
        "selltotal": "Vui lòng nhập tổng số tiền bạn muốn bán",
        "buyallnum": "Vui lòng nhập số lượng bạn muốn mua",
        "sellallnum": "Vui lòng nhập số lượng bạn muốn bán",
        "tradeTotal": "tổng số tiền giao dịch",
        "doceil": "Tự động hủy sau vài giây",
        "place": "Đặt hàng",
        "waitpay": "Trong khi chờ thanh toán",
        "paycode": "mã thanh toán",
        "finished": "hoàn thành",
        "ceiled": "Đã hủy",
        "payed": "Đã thanh toán",
        "nofinish": "hoàn tác",
        "buyer": "người mua",
        "seller": "người bán",
        "callWay": "Thông tin liên lạc",
        "placeTime": "thời gian phục vụ",
        "renum": "tài liệu tham khảo số",
        "canceil": "Hủy bỏ",
        "confirm": "xác nhận",
        "pwd": "Vui lòng nhập mật khẩu giao dịch",
        "coincode": "Đơn vị tiền tệ",
        "pcoin": "Vui lòng chọn đơn vị tiền tệ",
        "setTradePwd": "Đặt mật khẩu giao dịch?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Hồ sơ đặt hàng",
        "fatdLog": "Hồ sơ giao dịch tiền tệ hợp pháp",
        "shoper": "Thương gia",
        "faLog": "Hồ sơ giao dịch tiền tệ hợp pháp",
        "tdType": "Loại giao dịch",
        "odStatus": "Tình trạng đặt hàng",
        "pwaitPay": "Vui lòng chờ thanh toán của người mua",
        "odFinish": "Nhiệm vụ đã hoàn thành",
        "odCeil": "Lệnh hủy",
        "odPay": "Thanh toán đã được thực hiện, vui lòng kiểm tra kỹ thông tin thanh toán",
        "tdCeil": "hủy bỏ thỏa thuận",
        "pCeil": "Nếu bạn đã thanh toán cho người bán, vui lòng không hủy giao dịch.",
        "paySure": "Xác nhận thanh toán",
        "pdopay": "Vui lòng xác nhận rằng bạn đã thanh toán cho người bán",
        "badClick": "Các nhấp chuột độc hại sẽ trực tiếp đóng băng tài khoản",
        "receivePay": "Xác nhận thanh toán",
        "payReceive": "Vui lòng xác nhận rằng người mua đã thanh toán cho bạn",
        "receivePays": "Xác nhận đã nhận được thanh toán",
        // LegalPay
        "payPlease": "Vui long thanh toan",
        "tdPrice": "Đơn giá giao dịch",
        "tdNum": "Số lượng giao dịch",
        "payinfo": "Thông tin thanh toán",
        "banknum": "Tài khoản ngân hàng",
        "realName": "tên thật",
        "shoperZname": "Chi nhánh thương gia",
        "shoperNum": "tài khoản thương gia",
        "odCeil": "hủy đơn hàng",
        "imPay": "Tôi đã thanh toán, bấm xác nhận",
        // LegalSeller
        "register_time": "Thời gian đăng ký",
        "odtotal": "Trình tự lắp ráp",
        "odmonth": "Đơn hàng hoàn thành vào ngày 30",
        "odfinish": "Kết thúc đặt hàng",
        "odrate": "Tỷ lệ hoàn thành",
        "submit": "giải phóng",
        "phone": "điện thoại di động",
        "mysell": "của tôi để bán",
        "mybuy": "giao dịch mua hàng của tôi",
        "realAuth": "Đã xác minh",
        "advAuth": "Chứng nhận nâng cao",
        "linebuy": "mua trực tuyến",
        "linesell": "để bán trực tuyến",
        "tipPay": "Vui lòng liên hệ với người bán trong vòng 24 giờ để thanh toán. Nếu vượt quá 24 giờ, nó sẽ tự động bị hủy.",
        "notlow": "Không thể xuống dưới mức tối thiểu",
        "nothigh": "Không thể vượt quá giới hạn tối đa",
        "notnum": "Không thể vượt quá số lượng tối đa",
        // LegalShopDetail
        "type": "kiểu",
        "status": "tình trạng",
        "seeOrder": "kiểm tra đơn hàng",
        "withdraw": "rút",
        "abnormal": "bất thường",
        "lowershelf": "Đã xóa khỏi kệ",
        "protection": "Đang tiến hành bảo vệ quyền lợi",
        "apply": "Nộp đơn xin bảo vệ quyền",
        "psType": "Vui lòng chọn loại",
        "pselect": "xin vui lòng chọn",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "Thẻ ngân hàng",
        "minNum": "Khối lượng giao dịch tối thiểu",
        "maxNum": "khối lượng giao dịch tối đa",
        "wantBuy": "Muốn mua",
        "pnums": "Vui lòng nhập số lượng",
        "pmin": "Vui lòng nhập khối lượng giao dịch tối thiểu",
        "pmax": "Vui lòng nhập khối lượng giao dịch tối đa",
        "pnot": "Khối lượng giao dịch tối đa không thể nhỏ hơn khối lượng giao dịch tối thiểu",
        // MyLegalShops
        "myshops": "cửa hàng của tôi",
        "name": "tên",
        "fiatSub": "Tiền pháp định",
        "shoper_balance": "Số dư của người bán",
        "enterShop": "vào một cửa hàng",
        // shop_legal_pay_detail
        "payedWait": "Thanh toán đã được thực hiện, đang chờ xác minh và xác nhận",
        "yes": "Đúng",
        "no": "KHÔNG",
        "sd": "Khóa số dư",
        "journal": "Nhật ký số dư",
        "business": "Thương gia",
        "user": "người dùng",
        "backdone": "Đã rút",
        "busbalance": "Số dư của người bán",
        "userbalance": "Số dư người dùng",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C do tôi xuất bản",
        "myTrade": "C2C của giao dịch của tôi",
        "and": "Phải",
        "balance": "Sự cân bằng",
        "buynum": "Khối lượng mua",
        "sellnum": "Khối lượng bán",
        "bankTransfer": "Chuyển khoản ngân hàng",
        "payim": "Phải thanh toán trực tiếp",
        "pbuyNum": "Vui lòng nhập số lượng mua hàng",
        "pprice": "Vui lòng nhập đơn giá",
        "payway": "Vui lòng chọn phương thức thanh toán",
        "psellNum": "Vui lòng nhập số lượng bán",
        "suerePlace": "Xác nhận đơn hàng?",
        "detail": "Chi tiết",
        "payinfo": "Thông tin thanh toán",
        "Payee": "Người nhận thanh toán",
        "total": "số tiền một lần",
        "name": "Tên",
        "cardnum": "số thẻ",
        "receivePay": "Xác nhận đã nhận được thanh toán",
        "ceilConfirm": "Xác nhận hủy giao dịch?",
        "paySeller": "Vui lòng xác nhận rằng bạn đã thanh toán cho người bán. Các nhấp chuột độc hại sẽ khiến tài khoản của bạn bị đóng băng.",
        "payBuyer": "Vui lòng xác nhận rằng bạn đã nhận được thông tin thanh toán từ người mua",
        "account": "tài khoản",
        "sellerPay": "Vui lòng xác nhận rằng người mua đã thanh toán cho bạn?",
        "payattion": "Vui lòng thanh toán cho người bán càng sớm càng tốt sau khi thanh toán được xác nhận. Các nhấp chuột độc hại sẽ trực tiếp đóng băng tài khoản.",
        "log": "c2c đã hoàn thành đơn hàng",
    },
    // 合约
    "lever": {
        "nowentrust": "Thứ tự hiện tại",
        "hisentrust": "hoa hồng lịch sử",
        "lvchi": "vị thế hợp đồng",
        "all": "tất cả",
        "or": "hoặc",
        "gotrade": "Bắt đầu giao dịch",
        "tdrecord": "Ghi lại giao dịch",
        "dealed": "Giao dịch đã xong",
        "notdeal": "Không có giao dịch",
        "loading": "đang tải...",
        "entotal": "Tổng số tiền ủy thác",
        "canuse": "Có sẵn",
        "std": "giao dịch giá thị trường",
        "xtd": "hạn chế giao dịch",
        "pprice": "Vui lòng nhập giá",
        "phand": "Vui lòng nhập kích thước lô mua",
        "phandsell": "Vui lòng nhập kích thước lô bán",
        "pwd": "mật khẩu",
        "ppwd": "Vui lòng nhập mật khẩu giao dịch",
        "psw": "Mật khẩu giao dịch",
        "equal": "bình đẳng",
        "times": "lần",
        "timed": "nhiều",
        "hand": "tay",
        "hands": "số bước",
        "ptimes": "Hãy chọn nhiều",
        "phands": "Vui lòng chọn kích thước lô",
        "contractVal": "Giá trị thị trường hợp đồng",
        "bail": "lề",
        "canBail": "Ký quỹ khả dụng",
        "charge": "Phí dịch vụ giao dịch",
        "domore": "Mua (đi dài)",
        "doshort": "Bán (ngắn)",
        "dmore": "Đi lâu",
        "dshort": "ngắn",
        "sureOd": "Xác nhận đơn hàng",
        "noless": "Kích thước lô không thể nhỏ hơn",
        "nomore": "Kích thước lô không thể cao hơn",
        "tdnum": "Khối lượng giao dịch",
        "risk": "tỷ lệ rủi ro",
        "allloss": "Tổng lãi lỗ của các vị thế",
        "onehouse": "Đóng vị trí bằng một cú nhấp chuột",
        "type": "kiểu",
        "entrustPrice": "Giá đặt hàng",
        "openPrice": "giá mở cửa",
        "nowPrice": "giá hiện tại",
        "styPrice": "Lấy giá lãi",
        "stsPrice": "Giá dừng lỗ",
        "openTime": "Thời gian mở cửa",
        "closeTime": "Thời gian đóng cửa",
        "rate": "phí xử lý",
        "nightFee": "Phí qua đêm",
        "loss": "Lợi nhuận và thua lỗ",
        "setloss": "Đặt chốt lời và dừng lỗ",
        "expectProfit": "lợi nhuận kì vọng",
        "expectLoss": "tổn thất dự kiến",
        "allClose": "Đóng tất cả các vị thế",
        "moreClose": "Chỉ đóng các lệnh mua",
        "nullClose": "Chỉ đóng các lệnh bán",
        "sureClose": "Xác nhận để đóng vị thế?",
        "thanZearo": "Giá trị được đặt phải lớn hơn 0",
        "listin": "danh sách hàng chờ",
        "tdin": "trong giao dịch",
        "closein": "Đóng vị thế",
        "closed": "Vị thế đã đóng",
        "revoked": "Đã thu hồi",
        "revokeOrder": "Bạn có chắc chắn muốn hủy đơn hàng không?",
        "ping": "Đóng vị thế",
        "revoke": "Hủy đơn hàng",
        "sureping": "Bạn có chắc chắn đóng vị thế không?",
        "thanzone": "Giá trị được đặt phải lớn hơn 0",
    },
    // 币币
    "cuy": {
        "direction": "phương hướng",
        "total": "tổng cộng",
        "price": "giá ",
        "sell": "Bán",
        "buy": "mua",
        "allStation": "Giao dịch toàn bộ trang web",
        "buyPrice": "giá mua",
        "buynum": "Khối lượng mua",
        "sellPrice": "Gia ban",
        "sellnum": "Khối lượng bán",
        "tdPrice": "Khối lượng giao dịch",
        "or": "hoặc",
        "tdStart": "Bắt đầu giao dịch",
        "pbPrice": "Vui lòng nhập giá mua",
        "pbNum": "Vui lòng nhập số tiền mua",
        "psPrice": "Vui lòng nhập giá bán",
        "psNum": "Vui lòng nhập số lượng bán",
        "fixPrice": "hạn chế giao dịch",
        "Mtrans": "giao dịch giá thị trường",
        "available": "Có sẵn",
        "revoke": "Hủy bỏ",
        "loading": "đang tải",
        "confirmCancel": "Bạn có chắc là muốn hủy bỏ?",
        "confirm": "Chắc chắn",
        "cancel": "Hủy bỏ",
        "nomore": "Không còn dữ liệu",
        "evelPrice": "giá giao dịch trung bình",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Bạn có muốn xác nhận việc chuyển tiền không?",
        "assets": "tài sản",
        "currency_account": "Tài khoản tiền xu",
        "lever_account": "Tài khoản hợp đồng",
        "fince_account": "Tài khoản tiền pháp định",
        "change_account": "tài khoản giao dịch",
        "micro_account": "tài khoản hợp đồng thứ hai",
        "miscroAccount": "tài khoản hợp đồng thứ hai",
        "assets_hua": "Chuyển quĩ",
        "all_assets": "Tổng tài sản được chuyển đổi",
        "currency": "Tiền tệ",
        "canuse": "Có sẵn",
        "frezz": "đông cứng",
        "conversion": "chuyển đổi",
        "charging": "Gửi tiền xu",
        "withdraw": "Rút xu",
        "address_bind": "Ràng buộc địa chỉ rút tiền",
        "address_withdraw": "Quản lý địa chỉ rút tiền xu",
        "record": "Chi tiết",
        "tbrecord": "Kỷ lục rút tiền",
        "address_charge": "Địa chỉ gửi tiền",
        "address_width": "Địa chỉ rút tiền",
        "copy": "sao chép",
        "ercode": "mã QR",
        "reminder": "Lời khuyên tử tế",
        "notcharge": "Vui lòng không gửi bất kỳ khoản tiền nào vào địa chỉ trên.",
        "ornone": ", nếu không tài sản sẽ không được thu hồi",
        "support": "Tiền gửi chỉ hỗ trợ phương thức gửi đơn giản. Tiền gửi bằng các phương thức khác (gửi tất cả) không thể được ghi có tạm thời.",
        "minnum": "Số tiền rút tối thiểu",
        "ratenum": "phí xử lý",
        "havenum": "Số lượng đến",
        "from": "từ",
        "to": "đến",
        "transfer": "chuyển khoản",
        "tfnum": "Số lượng chuyển",
        "ptfnum": "Vui lòng nhập số tiền tối thiểu",
        "dtfnum": "Số lần chuyển",
        "canbalance": "Số dư khả dụng",
        "sureConfirm": "Xác nhận chuyển khoản",
        "moneyRecord": "Các báo cáo tài chính",
        "pNum": "Vui lòng nhập số tiền chuyển",
        "leverUse": "Tài khoản hợp đồng có sẵn",
        "legalUse": "Tài khoản Fiat có sẵn",
        "changeUselg": "Tài khoản tiền xu có sẵn",
        "changeUse": "Tài khoản giao dịch có sẵn",
        "microUse": "Tài khoản hợp đồng giây có sẵn",
        "look": "Kiểm tra",
        "chargeRecord": "Hồ sơ gửi tiền",
        "status": "trạng thái theo dõi",
        "noopen": "Chức năng này hiện chưa có",
        "enterAdderses": "Vui lòng nhập địa chỉ rút tiền",
        "enterNum": "Vui lòng nhập số tiền rút",
        "lessMin": "Số tiền rút đã nhập nhỏ hơn giá trị tối thiểu",
        "tips01": "Vui lòng không gửi bất kỳ tài sản nào khác vào địa chỉ trên, nếu không tài sản sẽ không được lấy lại.",
        "tips02": "Tiền gửi chỉ hỗ trợ phương thức gửi đơn giản. Tiền gửi bằng các phương thức khác (gửi tất cả) không thể được ghi có tạm thời.",
        "beizhu": "Nhận xét",
        "pbeizhu": "Vui lòng nhập nhận xét",
        "add": "Thêm vào",
        "addressList": "danh sách địa chỉ",
        "delete": "xóa bỏ",
        "norec": "Không có hồ sơ",
        "selectCurrency": "Vui lòng chọn loại tiền tệ",
        "chainType": "loại dây chuyền",
        "commissionLibrary": "thư viện hoa hồng",
    },
    // 账户设置
    "set": {
        "mycode2": "mã ủy quyền của tôi",
        "copycode2": "Sao chép mã ủy quyền",
        "uaccont": "Mức độ bảo mật tài khoản của bạn:",
        "strong": "mạnh mẽ",
        "middle": "ở giữa",
        "weak": "yếu đuối",
        "min": "Thấp",
        "heigh": "cao",
        "complete": "Điền đầy đủ thông tin để đảm bảo an toàn tài khoản",
        "mycode": "mã mời của tôi",
        "copycode": "Sao chép mã lời mời",
        "bindphone": "Ràng buộc điện thoại di động",
        "bindemail": "Ràng buộc email",
        "loginpwd": "mật khẩu đăng nhập",
        "noBind": "Không giới hạn",
        "binds": "ràng buộc",
        "bind": "Đi đến ràng buộc",
        "binded": "ràng buộc",
        "net": "Tài khoản Internet có nguy cơ bị đánh cắp. Bạn nên thay đổi mật khẩu thường xuyên để bảo vệ tính bảo mật cho tài khoản của mình.",
        "reset": "Ôn lại",
        "setPsw": "Đặt mật khẩu giao dịch để đảm bảo an toàn giao dịch.",
        "goSet": "Đi tới cài đặt",
        "bindAccount": "Tài khoản trao đổi ràng buộc",
        "duihuan": "Liên kết tài khoản để đổi điểm",
        "copysuccess": "Đã sao chép thành công",
        "copyfail": "Sao chép không thành công",
        "recopy": "Vui lòng sao chép lại",
        "account": "Tài khoản",
        "detail": "Chi tiết",
        "val": "Số lượng",
        "forgetPsw": "quên mật khẩu",
        "code": "Mã xác nhận",
        "getCode": "lấy mã xác minh",
        "setPsw": "đặt mật khẩu",
        "enterPsw": "Xin vui lòng nhập mật khẩu",
        "pswFalse": "Mật khẩu nhập hai lần không nhất quán",
        "enterPswagain": "Vui lòng nhập lại mật khẩu",
        "jsetPsw": "Đặt mật khẩu giao dịch",
        "jenterPsw": "Vui lòng nhập mật khẩu giao dịch",
        "jenterPswagain": "Vui lòng nhập lại mật khẩu giao dịch",
        "enterAccount": "Vui lòng nhập tên người dùng",
        "enterCode": "vui lòng nhập mã xác nhận",
        "accountFalse": "Điện thoại di động hoặc tài khoản email bạn đã nhập không tuân thủ các quy tắc!",
        "second": "giây",
        "sendCode": "Gửi mã xác minh",
        "sendsucc": "Gửi thành công",
        "enterPswTwo": "Vui lòng nhập mật khẩu của bạn hai lần",
        "rest": "Còn lại",
        "yes": "Đúng",
        "no": "KHÔNG",
        "yorn": "Có nên đặt mật khẩu giao dịch hay không",
        "authenticate": "Vui lòng thực hiện xác minh danh tính",
    },
    // 身份认证
    "auth": {
        "identity": "Xác thực",
        "name": "Tên",
        "idcard": "NHẬN DẠNG",
        "upimg": "Vui lòng tải ảnh CMND lên, ảnh đầu tiên là mặt trước, ảnh thứ hai là mặt sau và ảnh thứ ba là ảnh mặt trước cầm CMND.",
        "submit": "nộp",
        "auditing": "đang được xem xét...",
        "certified": "đã xác minh!",
        "pname": "Vui lòng nhập tên của bạn",
        "pidcard": "Vui lòng nhập số ID của bạn",
        "pimg": "Vui lòng tải lên hình ảnh giấy tờ tùy thân của bạn!",
    },
    // 收款方式
    "seting": {
        "pmethod": "phương thức thanh toán",
        "truename": "tên thật",
        "opening": "tên ngân hàng",
        "bank": "Số thẻ ngân hàng",
        "alipay": "tài khoản Alipay",
        "wename": "Biệt danh WeChat",
        "wechat": "tài khoản WeChat",
        "pname": "vui lòng nhập tên thật của bạn",
        "popen": "Vui lòng nhập tên ngân hàng nơi mở tài khoản",
        "pcard": "Vui lòng nhập số thẻ ngân hàng",
        "palipay": "Vui lòng nhập số tài khoản Alipay của bạn",
        "pwname": "Vui lòng nhập biệt danh WeChat của bạn",
        "pwaccount": "Vui lòng nhập tài khoản WeChat của bạn",
        "pimg1": "Mã thanh toán WeChat",
        "pimg2": "Mã thanh toán Alipay",
    },
    // 帮助中心
    "news": {
        "seeall": "xem tất cả",
        "helpcenter": "Trung tâm thông báo",
        "back": "trở lại",
    },
    //登录
    "login": {
        "welcome": "Vui lòng đăng nhập",
        "phoneLogin": "Đăng nhập bằng điện thoại",
        "emailLogin": "Đăng nhập email",
        "account": "tài khoản",
        "psw": "mật khẩu",
        "login": "Đăng nhập",
        "nouser": "Bạn chưa phải là người dùng FutureInvestment?",
        "goRegister": "Đăng ký ngay bây giờ và bắt đầu giao dịch trên nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới.",
        "registerFree": "đăng ký miễn phí",
        "psw6": "Mật khẩu không thể ít hơn sáu ký tự",
    },
    "register": {
        "register": "đăng ký",
        "phoneRegister": "Đăng ký điện thoại của bạn",
        "emailRegister": "đăng ky email",
        "country": "quốc tịch",
        "enterPhone": "Vui lòng nhập số điện thoại",
        "enterEmail": "vui lòng nhập email của bạn",
        "psw16": "Mật khẩu phải có từ 6-16 ký tự",
        "inviteCode": "Mã mời",
        "sel": "Yêu cầu",
        "sel2": "Không bắt buộc",
        "read": "tôi đã đọc và đồng ý",
        "xieyi": "\"Sự thỏa thuận của người dùng\"",
        "tips01": "Thông tin quốc tịch không thể được sửa đổi sau khi đăng ký, vì vậy hãy chắc chắn chọn nó một cách trung thực.",
        "tips02": "Email xác minh có thể bị xác định nhầm là thư rác, vì vậy vui lòng kiểm tra chúng cẩn thận.",
        "tips03": "Vui lòng giữ đúng số tài khoản FutureInvestment và mật khẩu đăng nhập của bạn.",
        "tips04": "Không sử dụng mật khẩu đăng nhập giống như trên các trang web khác.",
        "emailFalse": "Email bạn nhập không đúng quy định",
    },
    // 积分兑换
    "jc": {
        "title": "JC trao đổi tài sản kỹ thuật số nền tảng BEAU",
        "balance": "Sự cân bằng",
        "cannum": "Số lượng JC có sẵn",
        "new": "Giá mới nhất",
        "exnum": "Số lượng trao đổi",
        "pnum": "Vui lòng nhập số lượng JC",
        "canex": "Số lượng BEAU có thể được đổi",
        "exnow": "Đổi ngay bây giờ",
        "record": "Trao đổi hồ sơ",
        "exprice": "giá trao đổi",
        "extime": "Thời gian quy đổi",
        "not": "Không đủ điểm",
        "usenum": "Sử dụng số lượng JC",
        "out": "từ bỏ",
        "gotd": "đi buôn bán",
    },
    "coin": {
        "manage": "quản lý tài chính",
        "total": "tổng tiền gửi",
        "bao": "gửi kho báu",
        "mybao": "tiền gửi của tôi",
        "profit": "Doanh thu được ước tính",
        "cuntime": "Thời gian gửi tiền xu",
        "endtine": "Hạn sử dụng",
    },
    "team": {
        "myteam": "đội của tôi",
        "ztnum": "Số lượng giới thiệu trực tiếp",
        "activenum": "Số lượng thành viên tích cực của nhóm",
        "profit": "thu nhập",
        "back": "Hạ giá",
        "cblog": "Hồ sơ gửi tiền",
        "bblog": "kỷ lục tiền xu",
    },
    // k线
    "kline": {
        "text1": "chia sẻ thời gian",
        "text2": "1 phút",
        "text3": "5 phút",
        "text4": "15 phút",
        "text5": "30 phút",
        "text6": "1 giờ",
        "text7": "1 ngày",
        "text8": "1 tuần",
        "text9": "Tháng Một",
    },
    // 秒合约
    "miscro": {
        "trade": "trong giao dịch",
        "buyPrice": "giá mua",
        "finshPrice": "giá niêm yết",
        "loss": "Ước tính lãi lỗ",
        "times": "Đếm ngược",
        "mode": "mô hình giao dịch",
        "num": "Số lượng vị thế mở",
        "rate": "Khả năng sinh lời",
        "up": "mua lên",
        "down": "Mua hoặc bán",
        "openNum": "Vui lòng nhập số lượng mở bán",
        "success": "đã đặt hàng thành công!",
        "c2c": "tài khoản C2C",
        "complaint": "Đề xuất",
        "reply": "Vui lòng viết ra câu hỏi của bạn và chúng tôi sẽ liên hệ lại với bạn trong thời gian sớm nhất...",
        "complaintList": "Danh sách gợi ý khiếu nại",
        "complaintReply": "hồi đáp:",
        "complaintDescription": "Vui lòng nhập mô tả",
        "resetpwd": "đổi mật khẩu",
        "foundedOn": "Được xây dựng trong",
        "commonProblem": "vấn đề thường gặp",
        "statement": "giải thích",
        "about": "Giới thiệu về FutureInvestment",
        "everyone": "Mọi người đều là CEO",
        "program": "Chương trình đối tác toàn cầu của FutureInvestment",
        "program2": "Chương trình đối tác",
        "myMine": "Của tôi:",
        "friend": "Những người bạn khai thác của tôi:",
        "myRank": "Trình độ của tôi:",
        "accumulated": "Hoa hồng tích lũy:",
        "copyLinks": "Sao chép liên kết khuyến mãi",
        "moneyVein": "Biến kết nối thành tiền",
        "example": "Ví dụ về hoa hồng",
        "enterQuantity": "Vui lòng nhập số lượng",
        "contractBalance": "Số dư tài khoản hợp đồng",
        "submitRepeatedly": "Đang trong quá trình gửi, vui lòng không gửi lại nhiều lần",
        "alipayCode": "Mã thanh toán Alipay",
        "wechatCode": "Mã thanh toán WeChat",
        "realPrice": "giá thời gian thực",
        "currencyExchange": "Đổi tiền tệ",
        "currencyExchangeIn": "Đổi tiền tệ",
        "cashableBalance": "Số dư có sẵn để mua lại:",
        "minimumCashable": "Số tiền hoàn trả tối thiểu:",
        "maximumCashable": "Rút tiền tối đa:",
        "automaticallys": "Sau khi nhấp vào Đổi, nó sẽ tự động hoàn thành và không thể trả lại.",
        "match": "trao đổi",
        "title": "Trao đổi tài sản",
        "holdAssets": "nắm giữ tài sản",
        "dangerousCurrency": "Giữ tiền rủi ro",
        "convertibleQuantity": "Số lượng có thể đổi",
        "currencyExchanges": "Tỷ giá hối đoái:",
        "insuranceCurrency": "Đồng tiền bảo hiểm",
        "insuranceType": "loại bảo hiểm",
        "contractAsset": "Số tiền bảo hiểm tài sản hợp đồng",
        "warehouses": "Số lượng kho bảo hiểm",
        "availableAssets": "Tài sản có sẵn:",
        "insuredAssets": "Tài sản được bảo hiểm:",
        "insuredAssets1": "Tài sản được bảo hiểm",
        "insuranceAssets": "Tài sản bảo hiểm:",
        "purchaseInsurance": "Đăng ký bảo hiểm",
        "insuranceClaims": "yêu cầu bảo hiểm",
        "insuranceCancellation": "Hủy bảo hiểm",
        "coinWallet": "Ví tiền bảo hiểm",
        "bmbWallet": "Ví HAP",
        "cumulativeCoins": "Tiền tích lũy:",
        "availableQuantity": "Số lượng hiện có sẵn:",
        "rawCurrency": "Kỷ lục tiền xu",
        "contractInsurance": "hợp đồng bảo hiểm",
        "tenThousand": "Mười nghìn",
        "runningLow": "Thiếu cân bằng",
        "purchase": "Số lượng bạn đã nhập không tuân thủ các quy tắc và giới hạn mua hàng của bạn nằm trong",
        "reach": "đến",
        "between": "giữa",
        "onlyEnter": "Bạn chỉ có thể nhập",
        "integersBetween": "số nguyên giữa",
        "notReturned": "Khi bấm đăng ký mua bảo hiểm, bạn được coi là đã hiểu chi tiết hợp đồng bảo hiểm và đồng ý rằng kho bảo hiểm sẽ tự động có hiệu lực và không thể trả lại.",
        "settled": "Khi việc mất tài sản được bảo hiểm khiến không thể đặt hàng, yêu cầu bồi thường bảo hiểm sẽ được thực hiện",
        "profitable": "thời điểm tài sản đó tạo ra lợi nhuận",
        "terminated": ", bảo hiểm sẽ tự động chấm dứt.",
        "automatically": "Khi tài sản được bảo hiểm mất đi 50%, bạn phải nộp đơn yêu cầu bồi thường bảo hiểm, nếu không bạn sẽ không thể giao dịch. Khi lợi nhuận đạt 100%, kho bảo hiểm sẽ tự động chấm dứt hai khoản bồi thường một lần. ngày Nếu vượt quá 2 lần thì sẽ được bồi thường vào ngày t+1.",
        "termination": "Tài sản được bảo hiểm chỉ có thể được mua lại sau khi hợp đồng bảo hiểm bị chấm dứt. Sau khi hợp đồng bị chấm dứt, nó sẽ bị coi là vi phạm hợp đồng và vị thế bảo hiểm sẽ tự động bị xóa.",
        "just": "chỉ",
        "back": "đối diện",
        "lowestNumber": "Số lượng tối thiểu là",
        "confirmExchange": "Xác nhận đổi thưởng?",
        "contractValuation": "Định giá tài sản tài khoản hợp đồng",
        "secondValuation": "Định giá tài sản tài khoản hợp đồng thứ hai",
        "falshValuation": "Định giá tài sản tài khoản trao đổi tức thời",
        "c2cValuation": "Định giá tài sản tài khoản C2C",
        "recordWithdrawal": "Hồ sơ gửi và rút tiền",
        "category": "loại",
        "safetyCenter": "Trung tâm bảo vệ",
        "safeText1": "Dùng để nhận tin nhắn SMS xác minh khi đăng ký, đổi mật khẩu, thiết lập bảo mật",
        "safeText2": "Tài khoản Internet có nguy cơ bị đánh cắp. Bạn nên thay đổi mật khẩu thường xuyên để bảo vệ tính bảo mật cho tài khoản của mình.",
        "flashTrading": "Giao dịch chớp nhoáng",
        "assetCenter": "Trung tâm tài sản",
        "promotionCode": "Mã khuyến mãi của tôi",
        "loginAgain": "Đăng nhập đã hết hạn, vui lòng đăng nhập lại",
        "text10": "Giá theo thời gian thực dựa trên HAP",
        "text11": "Mã ủy quyền"
    }
}
